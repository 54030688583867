import {useEffect} from 'react'
import {useController, useWatch} from 'react-hook-form'
import {DateTimePicker} from '@mui/x-date-pickers/DateTimePicker'
import {defaultValues as initialDefaultValues} from 'components/events/defaultValues'
import dayjs from 'dayjs'

export default ({
	name,
	control,
	error
}) => {
	const {
		field: {
			onBlur,
			onChange,
			...field
		},
		formState: {
			touchedFields,
			defaultValues
		}
	} = useController({
		name,
		control,
		rules: {
			validate:  {
				disablePast: value => !dayjs(value).isBefore(dayjs()) || 'End date / time cannot be in the past.',
				notBeforeStart: value => !dayjs(value).isBefore(dayjs(startDate)) || 'End date / time cannot be before start date / time'
			}
		}
	})

	const startDate = useWatch({
		control,
		name: 'start'
	})

	useEffect(() => {
		if (!touchedFields[name] && startDate && defaultValues[name] === initialDefaultValues[name]) {
			onChange(startDate.add(4, 'hour'))
		}
	}, [startDate])

	return (
		<DateTimePicker
			{...field}
			onChange={onChange}
			variant='outlined'
			label='End Date / Time'
			disablePast
			disableHighlightToday
			format="dddd[,] Do MMMM YYYY [at] HH[:]mm"
			slotProps={{
				textField: {
					onBlur,
					size: 'small',
					error: !!error,
					helperText: error?.message,
					fullWidth: true,
					inputProps: (!touchedFields[name]) && {
						sx: {
							color: 'text.secondary'
						}
					}
				}
			}}
		/>
	)
}