import {useState} from 'react'
import {
	Box,
	Collapse,
	IconButton,
	Paper,
	Table as MuiTable,
	TableBody,
	TableContainer,
	Typography
} from '@mui/material'
import TableCell, {tableCellClasses} from '@mui/material/TableCell'
import TableRow, {tableRowClasses} from '@mui/material/TableRow'

import {
	KeyboardArrowUp,
	KeyboardArrowDown
} from '@mui/icons-material'


const Table = ({children, ...props}) => (
	<TableContainer>
		<MuiTable
			sx={{
				[`& .${tableCellClasses.root}`]: {
					borderBottom: 'none'
				},
				[`& .${tableRowClasses.root} .${tableCellClasses.root}:first-of-type`]: {
					minWidth: '150px'
				},
				display: 'table-cell',
				wordBreak: 'break-word'
			}}
			size='small'
			{...props}
		>
			{children}
		</MuiTable>
	</TableContainer>
)


const HeadlineArtistDetailsExpandable = ({
	headlineArtist
}) => {
	const [expanded, setExpanded] = useState(false)
	const toggleExpanded = () => setExpanded(!expanded)

	return (
		<Box>
			<Box sx={{
				display: 'flex',
				alignItems: 'center',
				ml: 1
			}}>
				<Typography variant='body2'>Headline artist details</Typography>
				<IconButton
					size='small'
					onClick={toggleExpanded}
					edge='end'
					sx={{ml: 1}}
				>
					{expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
				</IconButton>
			</Box>
			<Collapse
				in={expanded}
				timeout='auto'
			>
				<Table>
					<TableBody>
						{typeof headlineArtist === 'object' && Object.entries(headlineArtist).map(([key, value]) => (
							<TableRow key={key}>
								<TableCell component='th' scope='row'>
									{key}
								</TableCell>
								<TableCell>
									{Array.isArray(value) ? value.join(', ') : value}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</Collapse>
		</Box>
	)
}


const HeadlineArtistDetails = ({
	headlineArtist
}) => {
	return (
		<Paper sx={{p: 1}}>
			<Table>
				<TableBody>
					{typeof headlineArtist === 'object' && Object.entries(headlineArtist).map(([key, value]) => (
						<TableRow key={key}>
							<TableCell component='th' scope='row'>
								{key}
							</TableCell>
							<TableCell>
								{Array.isArray(value) ? value.join(', ') : value}
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</Paper>
	)
}


export default ({
	expandable,
	headlineArtist
}) => {
	return expandable ? (
		<HeadlineArtistDetailsExpandable headlineArtist={headlineArtist} />
	) : (
		<HeadlineArtistDetails headlineArtist={headlineArtist} />
	)
}