import {useEffect} from 'react'
import {useDispatch} from 'react-redux'

import {setLoading} from 'providers/slices/loading'
import {auth, onAuthStateChanged} from 'providers/firebase'
import {clearUser, setUser} from 'providers/slices/auth'


export default () => {
	const dispatch = useDispatch()

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, authUser => {
			if (authUser) {
				dispatch(setUser(({
					email: authUser?.email,
					name: authUser?.displayName,
					uid: authUser?.uid
				})))
			} else {
				dispatch(clearUser())
			}
			dispatch(setLoading(false))
		})
		return () => unsubscribe()
	}, [])
}