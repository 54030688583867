import {useEffect, useState} from 'react'
import {useController, useWatch} from 'react-hook-form'
import {
	Autocomplete,
	CircularProgress,
	Box,
	TextField,
	Tooltip,
	Typography
} from '@mui/material'

import mbSearchArtists from 'functions/musicbrainz.js'
import ISOCountries from 'data/ISOCountries'
import {useDebouncedCallback} from 'use-debounce'


const LightTypography = ({
	children,
	...props
}) => (
	<Typography
		fontSize='small'
		fontWeight='light'
		fontStyle='italic'
		color='lightgrey'
		{...props}
	>
		{children}
	</Typography>
)


const makeArtistArr = artists => (
	artists.filter(artist =>
		artist.name
	 && !artist['life-span']?.ended
	).map(artist => ({
		id: artist.id,
		name: artist.name,
		type: artist.type,
		disambiguation: artist.disambiguation,
		tags: artist.tags && artist.tags.map(tag => tag.name),
		country: artist.country,
		location: [
			artist.country ? (ISOCountries[artist.country] || artist.country) : [],
			(artist?.area?.type === 'City' && artist.area.name) ? artist.area.name : []
		].filter(x => x.length).join(', ')
	}))
)


const renderOption = (props, option) => (
	<Box
		component='li'
		sx={{
			gap: 2
		}}
		{...props}
		key={option.id}
	>
		<Box sx={{width: '20px'}}>
			{option.country && (
				<Tooltip
					arrow
					placement='left'
					title={ISOCountries[option.country]}
				>
					<img
						loading='lazy'
						width='20'
						src={`https://flagcdn.com/w20/${option.country.toLowerCase()}.png`}
						alt={option.country}
					/>
				</Tooltip>
			)}
		</Box>
		<Box sx={{
			display: 'flex',
			justifyContent: 'space-between',
			flexGrow: 1,
			gap: 2
		}}>
			<Box sx={{
				flexShrink: 0
			}}>
				<Typography>
					{option.name || '\xa0'}
				</Typography>
				<LightTypography>
					{option.type || '\xa0'}
				</LightTypography>
			</Box>
			<Box sx={{
				textAlign: 'right',
				flexShrink: 1
			}}>
				<LightTypography>
					{option.disambiguation || '\xa0'}
				</LightTypography>
				<LightTypography>
					{Array.isArray(option.tags) ? option.tags.slice(0, 4).join(', ') : '\xa0'}
				</LightTypography>
			</Box>
		</Box>
	</Box>
)


const getOptionLabel = option => option.name || ''


export default ({
	name,
	control,
	...props
}) => {
	const {field} = useController({
		name,
		control
	})

	const headline = useWatch({
		control,
		name: 'headlineArtist'
	})
	
	const [open, setOpen] = useState(false)
	const [loading, setLoading] = useState(false)
	
	const [inputValue, setInputValue] = useState('')
	const [searchResults, setSearchResults] = useState([])

	
	useEffect(() => {
		if (headline?.name) {
			setInputValue(headline.name)
		}
	}, [headline])


	const debouncedSearchQuery = useDebouncedCallback(
		async searchQuery => {
			if (searchQuery && searchQuery.length > 1) {
				setLoading(true)
				try {
					const result = await mbSearchArtists(searchQuery, 20)
					if (result.data?.artists?.length > 0) {
						setSearchResults(result.data.artists)
					}
				} catch (e) {
					console.error(e)
				} finally {
					setLoading(false)
				}
			}
		}, 500
	)


	const handleOpen = () => setOpen(true)

	const handleClose = (e, reason) => {
		setOpen(false)
		
		if (reason === 'blur' && typeof field.value === 'object') {
			return
		}

		if (reason !== 'selectOption') {
			handleChange(e.target?.value)
		}
	}


	const handleChange = newValue => {
		field.onChange(typeof newValue === 'string' ? {name: newValue} : newValue)
	}

	const handleInputChange = (e, newInputValue) => {
		if (!e?.target?.value) {
			setInputValue('')
			setSearchResults([])
			return
		}

		if (newInputValue && e.target.value !== inputValue) {
			debouncedSearchQuery(newInputValue)
			setInputValue(newInputValue)
			handleChange(newInputValue)
			return
		}

		if (e && !newInputValue) {
			if (e.type !== 'blur') {
				setInputValue('')
				setSearchResults([])
			}
			setOpen(false)
		}
	}
	
	const handleInputSelect = (e, newValue) => {
		setInputValue(newValue?.name || '')
		handleChange(newValue)
	}


	return (<>
		<Autocomplete
			{...field}
			autoHighlight
			blurOnSelect
			clearOnBlur
			clearOnEscape
			freeSolo
			fullWidth
			openOnFocus
			selectOnFocus

			loading={loading}
			open={open}
			onClose={handleClose}
			onOpen={handleOpen}

			inputValue={inputValue}
			onInputChange={handleInputChange}
			onChange={handleInputSelect}
			
			options={makeArtistArr(searchResults)}
			getOptionLabel={getOptionLabel}
			renderOption={renderOption}
			isOptionEqualToValue={(option, value) => option.id === value.id}

			renderInput={params => (
				<TextField
					{...params}
					size='small'
					label='Headline Artist'
					InputProps={{
						...params.InputProps,
						...loading && {endAdornment: <CircularProgress size={24} />}
					}}
				/>
			)}

			{...props}
		/>
	</>)
}