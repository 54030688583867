import {forwardRef} from 'react'
import {Link as MuiLink} from '@mui/material'
import {Link as WouterLink} from 'wouter'

export default forwardRef(({
	to = '/',
	title = '',
	children,
	...props
}, ref) => (
	<MuiLink
		component={WouterLink}
		href={to}
		underline='hover'
		ref={ref}
		{...props}
	>
		{title || children}
	</MuiLink>
))
