import {createSlice} from '@reduxjs/toolkit'

const initialState = 'TLR'

const pageTitleSlice = createSlice({
	name: 'pageTitle',
	initialState,
	reducers: {
		setPageTitle: (state, action) => {
			document.title = action.payload
			return action.payload
		}
	}
})

export const {setPageTitle} = pageTitleSlice.actions
export default pageTitleSlice.reducer