import {forwardRef} from 'react'
import {eventStatusColours} from 'data/colours.js'

import {
	Circle,
	Curtains,
	Nightlife,
	Schedule,
	TheaterComedy,
	Update,
	Cancel,
	VisibilityOff
} from '@mui/icons-material'

import {Icon} from '@iconify/react'


export const eventStatuses = [{
	name: 'On sale',
	slug: 'onSale',
	helpText: 'The event is confirmed and tickets are or will be on sale.',
	order: 1,
	colours: eventStatusColours.onSale,
	Icon: forwardRef((props, ref) => <Circle color='onSale' {...props} ref={ref} />)
}, {
	name: 'Sold out',
	slug: 'soldOut',
	helpText: 'The event is sold out and no more tickets are available.',
	order: 2,
	colours: eventStatusColours.soldOut,
	Icon: forwardRef((props, ref) => <Circle color='soldOut' {...props} ref={ref} />)
}, {
	name: 'Not announced',
	slug: 'notAnnounced',
	helpText: 'The event is confirmed, but can\'t be announced yet.',
	order: 3,
	colours: eventStatusColours.notAnnounced,
	Icon: forwardRef((props, ref) => <Schedule color='notAnnounced' {...props} ref={ref} />)
}, {
	name: 'Postponed',
	slug: 'postponed',
	helpText: 'The event has been postponed, and a new date is yet to be finalised / announced.',
	order: 4,
	colours: eventStatusColours.postponed,
	Icon: forwardRef((props, ref) => <Update color='postponed' {...props} ref={ref} />)
}, {
	name: 'Cancelled',
	slug: 'cancelled',
	helpText: 'The event has been cancelled, and will not be rescheduled.',
	order: 5,
	colours: eventStatusColours.cancelled,
	Icon: forwardRef((props, ref) => <Cancel color='cancelled' {...props} ref={ref} />)
}]


export const eventStatusesPrivate = [{
	name: 'Confirmed',
	slug: 'confirmed',
	helpText: 'The event is confirmed.',
	order: 1,
	colours: eventStatusColours.onSale,
	Icon: forwardRef((props, ref) => <Circle color='onSale' {...props} ref={ref} />)
}, {
	name: 'Postponed',
	slug: 'postponed',
	helpText: 'The event has been postponed, and a new date is yet to be finalised / announced.',
	order: 4,
	colours: eventStatusColours.postponed,
	Icon: forwardRef((props, ref) => <Update color='postponed' {...props} ref={ref} />)
}, {
	name: 'Cancelled',
	slug: 'cancelled',
	helpText: 'The event has been cancelled, and will not be rescheduled.',
	order: 5,
	colours: eventStatusColours.cancelled,
	Icon: forwardRef((props, ref) => <Cancel color='cancelled' {...props} ref={ref} />)
}]


export const ageRestrictions = [{
	name: '14+',
	slug: '14plus'
},{
	name: '16+',
	slug: '16plus',
},{
	name: '18+',
	slug: '18plus'
},{
	name: 'Under 18s',
	slug: 'under18s'
}]


export const eventTypes = [{
	name: 'Live music',
	slug: 'livemusic',
	Icon: forwardRef((props, ref) => <Icon icon='mdi:guitar-electric' height='18' {...props} ref={ref} />)
},{
	name: 'Clubnight',
	slug: 'clubnight',
	Icon: forwardRef((props, ref) => <Nightlife {...props} ref={ref} />)
},{
	name: 'Comedy',
	slug: 'comedy',
	Icon: forwardRef((props, ref) => <TheaterComedy {...props} ref={ref} />)
},{
	name: 'Other',
	slug: 'other',
	Icon: forwardRef((props, ref) => <Curtains {...props} ref={ref} />)
},{
	name: 'Private',
	slug: 'private',
	Icon: forwardRef((props, ref) => <VisibilityOff {...props} ref={ref} />)
}]


export const rooms = [{
	name: 'L1',
	slug: 'L1',
	capacity: 500
},{
	name: 'L2',
	slug: 'L2',
	capacity: 100
},{
	name: 'L1 & L2',
	slug: 'L1 & L2',
	capacity: 600
}]


export const allowedFileTypes = [
	'image/jpeg',
	'image/png',
	'image/svg',
	'image/webp'
]


export const allowedFileExtensions = [
	'jpeg',
	'jpg',
	'png',
	'svg',
	'webp'
]