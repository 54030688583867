import {
	Box,
	List,
	Paper,
	useMediaQuery,
	useTheme
} from '@mui/material'

export default ({
	children,
	sx,
	...props
}) => {
	const theme = useTheme()
	const smallViewport = useMediaQuery(theme.breakpoints.down('md'))
	return (
		<Box
			component={Paper}
			variant='outlined'
			sx={{
				position: 'sticky',
				top: theme.spacing(smallViewport ? 9 : 10),
				alignSelf: 'flex-start',
				width: {
					xs: theme.spacing(6.5),
					md: 'inherit'
				},
				...sx
			}}
			{...props}
		>
			<List dense>
				{children}
			</List>
		</Box>
	)
}