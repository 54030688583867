import {useCallback, useContext, useEffect, useMemo, useState} from 'react'
import Link from 'components/Link'
import {useTheme} from '@mui/material/styles'
import {useDispatch, useSelector} from 'react-redux'
import {db} from 'providers/firebase'
import {ref, update, onValue} from 'firebase/database'
import {setOpen} from 'providers/slices/sidebar'

import {eventStatuses} from 'data/eventOptions'
import {blank, renderBlank} from 'functions/blank.js'
import {formatDate, formatDateShort, formatDateVeryShort} from 'functions/formatDates'

import {
	Avatar,
	Box,
	Chip,
	Stack,
	Tooltip,
	useMediaQuery
} from '@mui/material'

import {
	DataGrid,
	GridActionsCellItem,
	GridFooterContainer,
	GridPagination,
	GridToolbarQuickFilter,
} from '@mui/x-data-grid'

import stringToColor from 'functions/stringToColor'

let errorId = 0


const renderStatusLine = ({value}) => {
	const status = eventStatuses.find(x => x.slug === value)
	return status ? (
		<Box sx={{
			width: '6px',
			height: '100%',
			backgroundColor: status.colours.hex
		}} />
	) : blank
}


const renderStatusIcon = ({value}) => {
	const status = eventStatuses.find(x => x.slug === value)
	return status ? (
		<Tooltip
			disableInteractive
			arrow
			placement='right'
			title={status.name}
		>
			<status.Icon
				sx={{
					fontSize: '1rem',
					verticalAlign: 'middle'
				}}
			/>
		</Tooltip>
	) : blank
}


const renderName = params => (
	<Link
		to={params.row.id}
		style={{
			color: 'inherit',
			textDecoration: 'none'
		}}
	>
		{params.row.name}
	</Link>
)


const Footer = () => {
	const theme = useTheme()
	const showCompact = useMediaQuery(theme.breakpoints.down('md'))
	return (<>
		<GridFooterContainer>
			<GridToolbarQuickFilter sx={{
				maxWidth: '40%',
				mx: showCompact ? 1 : 2,
				'& input': {
					fontSize: '.875rem'
				}
			}} />
			<GridPagination />
		</GridFooterContainer>
	</>)
}


function generateStaffObject() {
	// Helper function to generate a random name from a given set
	function getRandomName(namesSet, amount) {
		const randomNames = [];
		for (let i = 0; i < amount; i++) {
			const set = namesSet.filter(name => !randomNames.includes(name))
			const randomIndex = Math.floor(Math.random() * set.length);
			const randomName = set[randomIndex]
			randomNames.push(randomName);
		}
		return randomNames;
	}

	// Names sets for each category
	const namesSets = {
		'bar': [
			'Sam Edge',
			'Joe Parry',
			'Rhyl Joe',
			'Dave Smith',
			'Emily Byrne',
			'Meg Byrne',
			'Kerrie Wolfendale',
			'Danika Ulrich',
			'Reuben Coates',
			'James Burnham',
			'Caleb Pumford'
		],
		'box-office': [
			'Aled Owens',
			'Danika Ulrich',
			'Jack Stanley',
			'Kerrie Wolfendale'
		],
		'cloakroom': [
			'Aled Owens',
			'Danika Ulrich',
			'Jack Stanley',
			'Kerrie Wolfendale'
		],
		'crew': [
			'Terry Crews',
			'Tom Cruise',
			'Penelope Cruz',
			'Raymond Cruz'
		],
		'duty-manager': [
			'Aled Owens',
			'Kerrie Wolfendale',
			'Reuben Coates',
			'James Burnham',
			'Caleb Pumford'
		],
		'engineer-lighting': [
			'Mike Edwards',
			'Liam O\'Neill',
			'Ken',
			'Sadie',
			'Greg'
		],
		'engineer-sound': [
			'Liam O\'Neill',
			'Mike Edwards',
			'Laura',
			'Teige',
			'Greg'
		],
		'engineer-monitors': [
			'Liam O\'Neill',
			'Mike Edwards',
			'Laura',
			'Teige',
			'Greg'
		],
		'merch': [
			'Emily Byrne',
			'Danika Ulrich'
		],
		'security': [
			'Sam Purschke',
			'Lamin Faal',
			'Landing',
			'Rich',
			'James Burnham'
		],
		'stage-patch': [
			'Liam O\'Neill',
			'Mike Edwards',
			'Laura',
			'Teige',
			'Ken',
			'Sadie',
		],
		'venue-rep': [
			'Aled Owens',
			'Danika Ulrich',
			'Kerrie Wolfendale',
		],
	};


	// Initialize the staff object
	const staff = {};

	// Generate the staff members based on the provided criteria
	for (const category in namesSets) {
		staff[category] = [];

		// Determine the amount based on the criteria
		let amount = 0;
		switch (category) {
			case 'bar':
				amount = Math.random() < .3 ? Math.floor(Math.random() * 8) + 1 : Math.floor(Math.random() * 3) + 1;
				break;
			case 'bar-captain':
				amount = Math.random() < 0.5 ? Math.round(Math.random() * 2) + 1 : 1;
				break;
			case 'box-office':
				amount = 1;
				break;
			case 'cloakroom':
				amount = Math.random() < 0.25 ? 1 : 0;
				break;
			case 'crew':
				amount = Math.random() < .2 ? 3 : 0
				break;
			case 'duty-manager':
				amount = 1;
				break;
			case 'engineer-lighting':
				amount = Math.random() < 0.75 ? 1 : 0;
				break;
			case 'engineer-monitors':
				amount = Math.random() < 0.2 ? 1 : 0;
				break;
			case 'engineer-sound':
				amount = 1;
				break;
			case 'merch':
				amount = Math.random() < 0.05 ? 1 : 0;
				break;
			case 'security':
				amount = Math.random() < .3 ? Math.floor(Math.random() * 4) + 1 : 2;
				break;
			case 'stage-patch':
				amount = Math.random() < 0.4 ? 1 : 0;
				break;
			case 'venue-rep':
				amount = 1;
				break;
		}

		const randomNames = getRandomName(namesSets[category], amount)

		staff[category] = randomNames?.map((name, index) => {
			const forename = name.split(' ')[0]
			const surname = name.split(' ')[1] || '';

			const forenameCount = randomNames.filter(name => name.split(' ')[0] === forename).length
			const surnameFirstLetterCount = randomNames.filter(name => name.split(' ')[1]?.[0] === surname?.[0]).length

			if (forenameCount === 1) {
				return {
					slug: index + 1,
					name: forename//` ${forename}`
				}
			} else if (surnameFirstLetterCount === 1) {
				return {
					slug: index + 1,
					name: `${forename} ${surname[0]}`
				}
			} else {
				return {
					slug: index + 1,
					name//: ` ${name}`
				}
			}
		});
		// console.log(category, staff[category])
	}


	return staff;
}


const CustomChip = ({
	name,
	time,
	...props
}) => {
	return (
		<Chip
			label={(
				<Box sx={{
					display: 'flex',
					gap: .5
				}}>
					<Box>
						{name}
					</Box>
					{time && (
						<Box sx={{
							backgroundColor: `rgba(255, 255, 255, 0.1)`,
							borderRadius: 2,
							px: .5
						}}>
							{time}
						</Box>
					)}
				</Box>
			)}
			{...props}
		>

		</Chip>
	)
}


export default () => {
	const theme = useTheme()
	const [loading, setLoading] = useState(true)
	const dispatch = useDispatch()
	const {events} = useSelector(state => state.events)
	const showCompact = useMediaQuery(theme.breakpoints.down('md'))
	const [errors, setErrors] = useState({})
	const [staffingData, setStaffingData] = useState({
		personnel: [],
		roles: []
	})
	const [columnVisibilityModel, setColumnVisibilityModel] = useState({})

	const onColumnVisibilityModelChange = (model, details) => {
		console.log(model, details)
		setColumnVisibilityModel(prev => ({
			...prev,
			...model,
		}))
	}

	useEffect(() => {
		dispatch(setOpen(false))
	}, [])


	const addError = ({
		code,
		message
	}) => {
		setErrors([
			...errors,
			{
				cleared: false,
				id: errorId++,
				message,
				title: code,
			}
		])
	}
	const clearError = id => setErrors(errors.filter(error => error.id !== id))

	useEffect(() => {
		const listener = onValue(
			ref(db, '/staffing'),
			snapshot => {
				setLoading(true)
				const {personnel, roles} = snapshot.val()
				setStaffingData({
					personnel: Object.entries(personnel).map(([key, object]) => ({
						slug: key,
						...object
					})),
					roles: Object.values(roles)
				})
				setLoading(false)
			}, error => {
				setLoading(false)
				addError(error)
			}
		)

		return () => listener()
	}, [])

	const rolesRenderCell = ({
		row,
		value = []
	}) => (
		<Box
			sx={{
				display: 'flex',
				flexWrap: 'wrap',
				gap: .5,
				my: .5
			}}
		>
			{value.map(({
				// picture = '/#',
				name,
				slug
			}) => (
				<CustomChip
					avatar={(
						<Avatar
							sx={{
								bgcolor: stringToColor(name)
							}}
						>
							{name.charAt(0)}
						</Avatar>
					)}
					key={slug}
					name={name}
					size='small'
					sx={{
						fontSize: '.725rem'
					}}
					time='19:00'
					variant='outlined'
				/>
			))}
		</Box>
	)

	const columns = useCallback(() => [{
		filterable: true,
		hideable: false,
		// hideSortIcons: true,
		// sortable: true,
		field: 'status',
		headerName: 'Status',
		renderHeader: renderBlank,
		type: 'singleSelect',
		headerClassName: 'statusHeader',
		cellClassName: 'statusCell',
		valueOptions: eventStatuses.map(x => ({
			label: x.name,
			value: x.slug
		})),
		sortable: false,
		...showCompact ? {
			maxWidth: 6,
			minWidth: 6,
			renderCell: renderStatusLine
		} : {
			maxWidth: 48,
			minWidth: 48,
			renderCell: renderStatusIcon
		}
	}, {
		filterable: true,
		hideable: false,
		// hideSortIcons: true,
		// sortable: true,
		field: 'date',
		flex: 1,
		headerName: 'Date',
		minWidth: 150,
		type: 'dateTime',
		valueFormatter: ({value}) => showCompact ? formatDateVeryShort(value) : formatDateShort(value),
		valueGetter: ({value}) => value && new Date(value)
	}, {
		filterable: true,
		hideable: false,
		// hideSortIcons: true,
		// sortable: true,
		field: 'name',
		flex: 1.5,
		headerName: 'Name',
		minWidth: 150,
		renderCell: renderName,
		sortable: false
	}, {
		filterable: true,
		hideable: true,
		// hideSortIcons: true,
		// sortable: true,
		field: 'sales',
		flex: 1.5,
		headerName: 'Sales',
		minWidth: 50,
		sortable: true
	},
	...staffingData.roles.filter(({slug}) => ['bar', 'box-office', 'cloakroom', 'duty-manager', 'security', 'venue-rep'].includes(slug)).sort((a, b) => (a.name ?? '').localeCompare(b.name)).map(({
		name,
		slug
	}) => ({
		field: slug,
		headerName: name,
		minWidth: ['bar', 'security'].includes(slug) ? 288 : 160,
		renderCell: rolesRenderCell,
		sortable: false
	})),
	...staffingData.roles.filter(({slug}) => ['crew', 'engineer-sound', 'engineer-lighting', 'engineer-monitors', 'stage-patch'].includes(slug)).sort((a, b) => (a.name ?? '').localeCompare(b.name)).map(({
		name,
		slug
	}) => ({
		field: slug,
		headerName: name,
		minWidth: ['crew'].includes(slug) ? 288 : 160,
		renderCell: rolesRenderCell,
		sortable: false
	}))
	])

	const rows = useMemo(() => (
		events.map(event => ({
			id: event.uid,
			date: event.start,
			status: event.status,
			name: event.name,
			sales: Object.values(event.sales || {}).reduce((acc, currentValue) => acc + currentValue, 0),
			...generateStaffObject()
		}))
	), [events])

	const initialState = {
		sorting: {
			sortModel: [{
				field: 'date',
				sort: 'asc'
			}]
		}
	}

	const getRowHeight = () => 'auto'

	return events.length ? (
		<Box sx={{
			height: `calc(100vh - ${theme.spacing(12)})`
		}}>
			<Box sx={{
				height: '100%',
				display: 'flex'
			}}>
				<DataGrid
					autoPageSize
					columns={columns()}
					columnHeaderHeight={48}
					columnVisibilityModel={columnVisibilityModel}
					density='compact'
					disableRowSelectionOnClick
					getRowHeight={getRowHeight}
					initialState={initialState}
					loading={loading}
					onColumnVisibilityModelChange={onColumnVisibilityModelChange}
					rows={rows}
					slots={{
						footer: Footer
					}}
					sx={{
						...showCompact && {
							'& .MuiDataGrid-columnHeader.statusHeader': {
								marginRight: 1,
								paddingLeft: 0
							},
							'& .MuiDataGrid-columnHeader.statusHeader .MuiIconButton-root': {
								pl: 0
							},
							'& .MuiDataGrid-cell.statusCell': {
								padding: 0
							}
						}
					}}
				/>
			</Box>
		</Box>
	) : 'No events'
}