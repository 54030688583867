import {
	Alert as MuiAlert,
	AlertTitle
} from '@mui/material'

export default ({
	children,
	severity = 'error',
	title,
	sx,
	...props
}) => (
	<MuiAlert
		severity={severity}
		sx={{
			py: 0,
			...sx
		}}
		{...props}
	>
		{title && (
			<AlertTitle>
				{title}
			</AlertTitle>
		)}
		{children}
	</MuiAlert>
)