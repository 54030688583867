import {useController} from 'react-hook-form'
import {TextField} from '@mui/material'
import validateUrl from 'functions/validateUrl'

export default ({
	name,
	control,
	error,
	...props
}) => {
	const {field} = useController({
		name,
		control,
		rules: {
			validate: value => (!value || validateUrl(value)) || 'Invalid URL'
		}
	})

	return (
		<TextField
			{...field}
			fullWidth
			size='small'
			variant='outlined'
			label='Ticket URL'
			type='url'
			error={!!error}
			helperText={error?.message}
			{...props}
		/>
	)
}