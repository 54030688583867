import {
	CircularProgress,
	Typography
} from '@mui/material'
import CenterBox from 'components/CenterBox'

export default ({
	message,
	...props
 }) => (
	<CenterBox {...props}>
		<CircularProgress />
		{message && (
			<Typography align='center'>
				{message}
			</Typography>
		)}
	</CenterBox>
)