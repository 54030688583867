import {useState, useEffect, forwardRef} from 'react'
import axios from 'axios'
import {api} from 'functions/axios'

import {useSelector} from 'react-redux'
import {useTheme} from '@mui/material/styles'

import {
	Box,
	Button,
	ButtonGroup,
	Paper,
	FormControl,
	TextField,
	ToggleButton as MuiToggleButton
} from '@mui/material'
import * as Mui from '@mui/material'
import * as Icons from '@mui/icons-material'

import {db} from 'providers/firebase.js'
import {ref, update, set, onValue} from 'firebase/database'

// import {useGetUsersQuery} from 'providers/slices/firebaseApi'

export default () => {
	const theme = useTheme()

	return (
		<Box sx={{
			height: '100%',
			overflow: 'hidden'
		}}>
			
		</Box>
	)
}