import {
	getAuth,
	firebaseApp
} from 'providers/firebase'
import {verifyToken} from 'functions/axios'
import {
	clearUser,
	setError,
	setVerifying,
	setVerifiedUser
} from 'providers/slices/auth'

import {permissionsSlugs} from 'data/permissions'

export default async (
	dispatch,
	authState
) => {
	const {
		user,
		verifying
	} = authState

	if (verifying) {
		return
	}

	console.log(`[verifyUser]`, `verifying user`)
	dispatch(setVerifying(true))

	try {
		const token = await getAuth(firebaseApp).currentUser.getIdToken(true)
		const verifyTokenResponse = await verifyToken(token)
		if (verifyTokenResponse.status !== 200) {
			throw(new Error('Error whilst verifying token'))
		}

		const jwt = await getAuth(firebaseApp).currentUser.getIdTokenResult(true)

		const currentUser = await getAuth(firebaseApp).currentUser

		const permissionsTest = permissionsSlugs.every(permission => verifyTokenResponse.data.permissions[permission] === jwt.claims.permissions[permission])
		if (!permissionsTest) {
			throw(new Error('Permissions mismatch.'))
		} else {
			console.log(`[verifyUser]`, `user ${currentUser?.email} verified`)
			const data = verifyTokenResponse.data
			const permissions = Object.keys(data.permissions || {}).filter(permission => !!data.permissions[permission])
			data.permissions = permissions
			dispatch(setVerifiedUser(data))
		}

	} catch (error) {
		console.error(error)
		dispatch(clearUser())
		dispatch(setError(error.message ?? error))
	} finally {
		dispatch(setVerifying(false))
	}
}
