import {useTheme} from '@mui/material/styles'
import {rooms} from 'data/eventOptions'
import {ticketOutlets} from 'data/ticketOutlets'

import {
	Box,
	Typography,
	LinearProgress,
	Stack,
	Fade
} from '@mui/material'

export default ({
	event = {}
}) => {
	const theme = useTheme()
	if (event.uid && !event.sales) {
		return (
			<Typography variant='body2' sx={{mt: 2, fontStyle: 'oblique'}}>
				There are no sales for {event.name} at this time.
			</Typography>
		)
	}

	const filteredSales = Object.entries(event.sales ?? {})
		.filter(([type, amount]) =>
			type !== 'guestlist'
		//  &&	type !== 'door'
		 && amount > 0
		)
	const totalSales = filteredSales.reduce((total, currentValue) => total + currentValue[1], 0)
	const roomCap = rooms.find(room => event.room === room.slug)?.capacity

	return (
		<Stack spacing={1.5}>
			{[
				...filteredSales.sort((a, b) => {
					const labelA = ticketOutlets.find(x => x.slug === a[0])?.name
					const labelB = ticketOutlets.find(x => x.slug === b[0])?.name
					return labelA === labelB ? 0 : labelA > labelB ? 1 : -1
				}),
				['Total', totalSales]
			].map(([outlet, amount], i) => (
				<Fade
					in
					timeout={filteredSales.length > 0 ? i * (3000/filteredSales.length) : 0}
					key={outlet+'-'+i}
				>
					<Box>
						<Typography variant='subtitle2'>
							{ticketOutlets.find(x => x.slug === outlet)?.name || outlet}
						</Typography>
						<Box sx={{display: 'flex', alignItems: 'center'}}>
							<Box sx={{width: '100%', position: 'relative'}}>
								<LinearProgress
									variant='determinate'
									color={theme.palette[outlet] ? outlet : event.status}
									value={(outlet === 'Total' ? (totalSales / roomCap) : (amount / totalSales)) * 100}
									sx={{
										height: 18,
										border: `1px solid ${theme.palette.divider}`
									}}
								/>
								<Box sx={{
									top: 0,
									bottom: 0,
									right: 0,
									position: 'absolute',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center'
								}}>
									<Typography
										variant='body2'
										align='right'
										sx={{
											mr: .5,
											color: theme.palette[outlet]?.contrastText,
											fontSize: '.75rem'
										}}
									>
										{outlet === 'Total' ? `${amount} / ${roomCap}` : amount}
									</Typography>
								</Box>
							</Box>
						</Box>
					</Box>
				</Fade>
			))}
		</Stack>
	)
}
