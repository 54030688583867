import {useController} from 'react-hook-form'
import {TextField} from '@mui/material'

export default ({
	name,
	error,
	control,
	...props
}) => {
	const {field} = useController({
		name,
		control,
		rules: {
			maxLength: {
				value: 10000,
				message: 'Max length: 10000'
			}
		}
	})
	
	return (
		<TextField
			{...field}
			fullWidth
			size='small'
			variant='outlined'
			label='Description'
			multiline
			rows={16}
			error={!!error?.message}
			helperText={error?.message}
			inputProps={{
				sx: {
					resize: 'vertical'
				}
			}}
			{...props}
		/>
	)
}