import {useState, useEffect, forwardRef} from 'react'
import axios from 'axios'
import {api} from 'functions/axios'

import {useSelector} from 'react-redux'
import {useTheme} from '@mui/material/styles'

import {
	Box,
	Button,
	ButtonGroup,
	Paper,
	FormControl,
	TextField,
	ToggleButton as MuiToggleButton
} from '@mui/material'
import * as Mui from '@mui/material'
import * as Icons from '@mui/icons-material'

import {db} from 'providers/firebase.js'
import {ref, update, set, onValue} from 'firebase/database'


const questions = [{
	label: 'Question One',
	slug: 'question_one',
	options: [{
		yes: {
			label: 'Yes',
			slug: 'yes',
			type: 'boolean'
		},
		no: {
			label: 'No',
			slug: 'no',
			type: 'boolean'
		},
		comments: {
			label: 'Comments',
			slug: 'comments',
			type: 'text'
		}
	}]
}]


const potentialOptions = [{
	label: 'Yes',
	slug: 'yes',
	color: 'success'
},{
	label: 'Yes, with issues',
	slug: 'yes_with_issues',
	color: 'warning'
},{
	label: 'No',
	slug: 'no',
	color: 'error'
}]



// const Question = forwardRef(({
// 	question,
// 	questionIndex,
// 	options,
// 	handleClickNext,
// 	...props
// }, ref) => {
// 	const theme = useTheme()
// 	const [selected, setSelectedOption] = useState(null)

// 	const selectedOptions = potentialOptions.filter(x => options.includes(x.slug))
// 	const commentsRequired = (selected === 'yes_with_issues')

// 	const handleButtonClick = slug => () => {
// 		if (selected === slug) {
// 			handleClickNext()
// 		} else {
// 			setSelectedOption(slug)
// 		}
// 	}

// 	return (
// 		<Box
// 			ref={ref}
// 			{...props}
// 		>
			
// 		</Box>
// 	)
// })



export default () => {
	const theme = useTheme()
	const [selectedOption, setSelectedOption] = useState(null)
	const [activeQuestion, setActiveQuestion] = useState(0)

	const handleClickPrev = () => setActiveQuestion(prev => (prev - 1 + questions.length) % questions.length)
	const handleClickNext = () => setActiveQuestion(prev => (prev + 1) % questions.length)
	const handleButtonClick = slug => () => {
		if (slug === selectedOption) {
			handleClickNext()
		} else {
			setSelectedOption(slug)
		}
	}

	const questions = [{
		question: 'Customer area bar cleaned, clear, tidy and checked for damage',
		options: [
			'yes',
			'yes_with_issues',
			'no'
		]
	},{
		question: 'Customer area tables & seating areas cleaned, clear, tidy and checked for damage',
		options: [
			'yes',
			'yes_with_issues',
			'no'
		]
	},{
		question: 'Customer area floors cleaned, clear, and tidy',
		options: [
			'yes',
			'yes_with_issues',
			'no'
		]
	}]

	const filterOptions = options => potentialOptions.filter(x => options.includes(x.slug))
	const commentsRequired = (selectedOption === 'yes_with_issues')

	return (
		<Box sx={{
			height: '100%',
			overflow: 'hidden'
		}}>
			{questions.map(({question, options}, i) => (
				<Mui.Slide
					key={`question-${i}`}
					direction={i < activeQuestion ? 'left' : 'right'}
					in={i === activeQuestion}
					mountOnEnter
					unmountOnExit
					appear={false}
				>
					<Box sx={{
						display: 'flex',
						flexDirection: 'column',
						gap: 2,
						height: '100%'
					}}>
						{/* <Question
							question={question}
							questionIndex={i}
							options={options}
							handleClickNext={handleClickNext}
						/> */}
						<Box>
							<Mui.Typography variant='h4'>
								{i+1}. {question}
							</Mui.Typography>
						</Box>
						<Box sx={{
							display: 'flex',
							gap: 1,
							flexGrow: 1
						}}>
							<Mui.ToggleButtonGroup
								exclusive
								fullWidth
								orientation='vertical'
								sx={{
									gap: 1
								}}
							>
								{filterOptions(options).map(({
									label,
									slug,
									color
								}) => (
									<Mui.ToggleButton
										fullWidth
										disableRipple
										key={slug}
										value={slug}
										aria-label={label}
										onClick={handleButtonClick(slug)}
										selected={slug === selectedOption}
										variant='contained'
										sx={{
											fontSize: '1.75rem',
											flexGrow: 1,
											backgroundColor: theme.palette[color].dark,
											border: '4px transparent solid !important',
											transition: theme.transitions.create('background', {
												duration: theme.transitions.duration.standard,
												easing: theme.transitions.easing.easeInOut
											}),
											':hover': {
												backgroundColor: `${theme.palette[color].main} !important`
											},
											'&.Mui-selected': {
												background: theme.palette[color].main,
												color: 'white',
												borderColor: 'white !important'
											}
										}}
									>
										{label}
									</Mui.ToggleButton>
								))}
							</Mui.ToggleButtonGroup>
						</Box>
						<Box>
							<Mui.TextField
								fullWidth
								required={commentsRequired}
								multiline
								label='Comments'
								aria-label='Comments'
							/>
						</Box>
						<Box sx={{
							display: 'flex',
							justifyContent: 'space-between'
						}}>
							<Button
								aria-label='go back'
								variant='contained'
								size='large'
								sx={{py: 2, px: 4}}
								disabled={i === 0}
								onClick={handleClickPrev}
							>
								Previous
							</Button>
							<Button
								aria-label='next'
								variant='contained'
								size='large'
								sx={{py: 2, px: 4}}
								onClick={handleClickNext}
							>
								{i === (questions.length-1) ? 'Submit' : 'Next'}
							</Button>
						</Box>
					</Box>
				</Mui.Slide>
				
			))}
		</Box>
	)
}