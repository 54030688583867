import {useSelector} from 'react-redux'
import {Redirect} from 'wouter'
import {
	Box,
	Typography
} from '@mui/material'


export default () => {
	const {userData} = useSelector(state => state.userData)

	return userData.newUser ? (
		<Box>
			<Typography marginBottom={2} variant='h2'>
				Welcome, {userData.name.split(' ')[0]}
			</Typography>
			<Typography variant='body'>
				It looks like you don't have access to any sections just yet.
			</Typography>
		</Box>
	) : (
		<Redirect to='/' />
	)
}