import {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'wouter'
import {useForm} from 'react-hook-form'

import {setPageTitle} from 'providers/slices/pageTitle'
import {getAuth} from 'providers/firebase'
import {api} from 'functions/axios'
import {makeUpdateEventFormData} from 'functions/makeEventFormData'
import {eventTypes} from 'data/eventOptions'
import Error from 'pages/Error'
import {
	Box,
	Divider,
	Table as MuiTable,
	TableBody,
	TableContainer
} from '@mui/material'
import TableCell, {tableCellClasses} from '@mui/material/TableCell'
import TableRow, {tableRowClasses} from '@mui/material/TableRow'
import LoadingButton from '@mui/lab/LoadingButton'
import {
	ConfirmationNumberOutlined,
	ContactMailOutlined,
	EventNoteOutlined,
	Web
} from '@mui/icons-material'
import {Icon} from '@iconify/react'

import Loading from 'components/Loading'
import Section from 'components/events/Section'
import SubSection from 'components/events/SubSection'
import SectionLink from 'components/events/SectionLink'
import StickySidebar from 'components/events/StickySidebar'
import {defaultValues} from 'components/events/defaultValues'
import FormBox from 'components/events/FormBox'
import SubmitDialog from 'components/events/SubmitDialog'
import LastUpdatedValue from 'components/events/LastUpdatedValue'
import {
	AgeRestriction,
	ContactEmail,
	ContactName,
	ContactPromoter,
	Description,
	DoorPrice,
	EndDate,
	EventName,
	FlagCheckbox,
	HeadlineArtist,
	HeadlineArtistPreview,
	HeadlineArtistStartTime,
	ImageUpload,
	Room,
	StartDate,
	Status,
	StatusPrivate,
	SupportArtists,
	TicketPrice,
	TicketUrl,
	YoutubeId,
	WebsiteEventName
} from 'components/events/fields'

import Th from 'components/Th'
import dayjs from 'dayjs'


const Table = ({children, ...props}) => (
	<TableContainer>
		<MuiTable
			sx={{
				[`& .${tableCellClasses.root}`]: {
					borderBottom: 'none'
				},
				[`& .${tableRowClasses.root} .${tableCellClasses.root}:first-of-type`]: {
					minWidth: '150px'
				},
				display: 'table-cell'
			}}
			size='small'
			{...props}
		>
			{children}
		</MuiTable>
	</TableContainer>
)



const dayjsDefault = timestamp => timestamp ? dayjs(timestamp) : null


const EditEvent = ({event}) => {
	const [loading, setLoading] = useState(false)
	const [dialogOpen, setDialogOpen] = useState(false)
	const [apiResponse, setApiResponse] = useState({})
	const [formDisabled, setFormDisabled] = useState(false)

	const {
		control,
		handleSubmit,
		setValue,
		setError,
		clearErrors,
		formState: {
			errors
		},
		watch
	} = useForm({
		mode: 'all',
		defaultValues,
		values: {
			ageRestriction: event.ageRestriction,
			headlineArtist: event.headlineArtist ?? {
				name: ''
			},
			headlineArtistStartTime: dayjsDefault(event.headlineArtistStartTime),
			description: event.description,
			displayName: event.displayName,
			end: dayjsDefault(event.end),
			externalContact: event.externalContact,
			hideFromUpcoming: event.hideFromUpcoming,
			hideFromWebsite: event.hideFromWebsite,
			image: event.image ?? {},
			imageRemoved: false,
			name: event.name,
			notes: event.notes,
			prices: event.prices,
			room: event.room,
			start: dayjsDefault(event.start),
			status: event.status,
			supportArtists: event.supportArtists,
			ticketUrl: event.ticketUrl,
			type: event.type,
			youtubeId: event.youtubeId
		}
	})

	const eventType = watch('type')
	const hideFromWebsite = watch('hideFromWebsite')

	const onSubmit = async data => {
		setLoading(process.env.NODE_ENV === 'production')
		setFormDisabled(process.env.NODE_ENV === 'production')
		setApiResponse({})
		setDialogOpen(true)
		const token = await getAuth().currentUser.getIdToken(true)
		api({
			method: 'post',
			url: eventType === 'private' ? `/events/updatePrivate/${event.uid}` : `/events/update/${event.uid}`,
			headers: {
				token
			},
			data: makeUpdateEventFormData(data, event)
		}).then(response => {
			setApiResponse(response.data)
		}).catch(response => {
			setApiResponse(response)
			setFormDisabled(false)
			console.error(response)
		}).finally(() => setLoading(false))
	}


	return (<>
		<Box sx={{
			display: 'flex',
			alignItems: 'flex-start',
			gap: {
				xs: 1,
				md: 2
			}
		}}>
			<StickySidebar>
				<SectionLink
					icon={<EventNoteOutlined fontSize='small' />}
					name='Basic Information'
					target='#basicInformation'
				/>
				{eventType !== 'private' && (<>
					<SectionLink
						icon={<Icon icon='mdi:guitar-electric' height='18' />}
						name='Performing Artists'
						target='#artistInformation'
					/>
					<SectionLink
						icon={<ConfirmationNumberOutlined fontSize='small' />}
						name='Ticketing & Entry'
						target='#ticketing'
					/>
					<SectionLink
						icon={<Web fontSize='small' />}
						name='Website Fields'
						target='#website'
					/>
					<SectionLink
						icon={<ContactMailOutlined fontSize='small' />}
						name='External Contact'
						target='#externalContact'
					/>
				</>)}
			</StickySidebar>
			<Box sx={{
				display: 'flex',
				flexDirection: 'column',
				flexGrow: 1,
				gap: {
					xs: 1,
					md: 2
				}
			}}>
				<form
					autoComplete='off'
					onSubmit={handleSubmit(onSubmit)}
				>
					<Box sx={{
						display: 'flex',
						flexFlow: 'column',
						justifyContent: 'space-evenly',
						gap: 2,
						maxWidth: '960px'
					}}>

						<Section
							id='basicInformation'
							name='Basic Information'
						>
							<Table>
								<TableBody>
									<TableRow>
										<Th>Event type</Th>
										<TableCell>
											{eventTypes.find(x => x.slug === event.type)?.name || event.type}
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>
											Last updated
										</TableCell>
										<TableCell>
											{event.updates && <LastUpdatedValue updates={event.updates} />}
										</TableCell>
									</TableRow>
									<TableRow>
										<Th>UID</Th>
										<TableCell>
											{event.uid}
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>

							<EventName
								name='name'
								control={control}
								error={errors?.name}
								formType='update'
							/>

							{eventType === 'private' ? (
								<StatusPrivate
									name='status'
									control={control}
								/>
							) : (
								<Status
									name='status'
									control={control}
								/>
							)}

							<FormBox>
								<StartDate
									name='start'
									control={control}
									error={errors?.start}
								/>
								<EndDate
									name='end'
									control={control}
									error={errors?.end}
								/>
							</FormBox>

							<Room
								name='room'
								control={control}
							/>
						</Section>


						{eventType !== 'private' && (<>
							<Section
								id='artistInformation'
								name='Performing Artists'
							>
								<FormBox>
									<HeadlineArtist
										name='headlineArtist'
										control={control}
									/>
									<HeadlineArtistStartTime
										name='headlineArtistStartTime'
										control={control}
									/>
								</FormBox>
								<HeadlineArtistPreview control={control} />
								<SupportArtists
									name='supportArtists'
									control={control}
									setValue={setValue}
								/>
							</Section>


							<Section
								id='ticketing'
								name='Ticketing & Entry'
							>
								<AgeRestriction
									name='ageRestriction'
									control={control}
								/>
								<FormBox>
									<TicketPrice
										name='prices.ticket'
										control={control}
										error={errors?.prices?.ticket}
									/>
									<DoorPrice
										name='prices.door'
										control={control}
										error={errors?.prices?.door}
									/>
								</FormBox>
							</Section>


							<Section
								id='website'
								name='Website Fields'
							>
								<SubSection sx={{gap: 0}}>
									<FlagCheckbox
										control={control}
										label='Omit the event from public website listings'
										name='hideFromWebsite'
									/>

									<FlagCheckbox
										control={control}
										label='Omit the event from the "Upcoming Events" section of theliverooms.com'
										name='hideFromUpcoming'
										disabled={hideFromWebsite}
									/>
								</SubSection>

								<Divider />

								<SubSection
									description='Optionally configure a custom display name for the event on the website(s) instead of the Event Name.'
									disabled={hideFromWebsite}
									title='Website Display Name'
								>
									<WebsiteEventName
										name='displayName'
										control={control}
										error={errors?.displayName}
										disabled={hideFromWebsite}
									/>
								</SubSection>

								<Divider />

								<SubSection
									description='The primary ticket URL for the event.'
									disabled={hideFromWebsite}
									title='Ticket URL'
								>
									<TicketUrl
										control={control}
										disabled={hideFromWebsite}
										error={errors?.ticketUrl}
										name='ticketUrl'
									/>
								</SubSection>

								<Divider />

								<SubSection
									description="A description of the event, e.g the artist's bio. This field can also be used to include any information or details relevant to the event that isn't covered by any of the other fields."
									disabled={hideFromWebsite}
									title='Description'
								>
									<Description
										name='description'
										control={control}
										error={errors?.description}
										disabled={hideFromWebsite}
									/>
								</SubSection>

								<Divider />

								<SubSection
									description="An image of the performing artist, their logo, or other artwork associated with the event."
									disabled={hideFromWebsite}
									title='Image'
								>
									<ImageUpload
										name='image'
										control={control}
										error={errors?.image}
										setError={setError}
										clearErrors={clearErrors}
										uid={event.uid}
										hasImage={event.image?.filename}
										disabled={hideFromWebsite}
									/>
								</SubSection>

								<Divider />

								<SubSection
									description='The ID of A YouTube video to be included with the event listing.'
									disabled={hideFromWebsite}
									title='YouTube Video'
								>
									<YoutubeId
										control={control}
										disabled={hideFromWebsite}
										error={errors?.youtubeId}
										name='youtubeId'
									/>
								</SubSection>
							</Section>


							<Section
								id='externalContact'
								name='External Contact (Promoter / agent / hire / etc)'
							>
								<ContactPromoter
									name='externalContact.promoter'
									control={control}
									error={errors?.externalContact?.promoter}
								/>
								<ContactName
									name='externalContact.name'
									control={control}
									error={errors?.externalContact?.name}
								/>
								<ContactEmail
									name='externalContact.email'
									control={control}
									error={errors?.externalContact?.email}
								/>
							</Section>
						</>)}

						<Box>
							<LoadingButton
								disabled={formDisabled}
								loading={loading}
								variant='contained'
								color='primary'
								type='submit'
							>
								Update Event
							</LoadingButton>
						</Box>

					</Box>
				</form>


				<SubmitDialog
					open={dialogOpen}
					setOpen={setDialogOpen}
					type='update'
					response={apiResponse}
					loading={loading}
				/>
			</Box>
		</Box>
	</>)
}


export default () => {
	const params = useParams()
	const {
		events,
		loading
	} = useSelector(state => state.events)
	const dispatch = useDispatch()
	const findEvent = events.find(event => event.uid === params.uid)

	useEffect(() => {
		if (findEvent) {
			dispatch(setPageTitle(`Editing ${findEvent.name}`))
		}
	}, [events, findEvent])

	return loading ? (
		<Loading />
	) : (
		findEvent ? (
			<EditEvent event={findEvent} />
		) : (
			<Error error={{
				message: 'Event Not Found',
				data: `Couldn't find event with ID ${params.uid}`
			}} />
		)
	)
}
