import {useController} from 'react-hook-form'
import {
	TextField,
	MenuItem
} from '@mui/material'
import {eventTypes} from 'data/eventOptions'

export default ({
	name,
	control,
	...props
}) => {
	const {field} = useController({
		name,
		control
	})
	
	return (
		<TextField
			{...field}
			fullWidth
			required
			select
			variant='outlined'
			size='small'
			label='Event Type'
			{...props}
		>
			{eventTypes.map(type => (
				<MenuItem value={type.slug} key={type.slug}>
					{type.name}
				</MenuItem>
			))}
		</TextField>
	)
}