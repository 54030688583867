import {
	IconButton,
	Collapse,
	Alert
} from '@mui/material'

import {Close} from '@mui/icons-material'

export default ({
	trigger,
	severity,
	content,
	onClick
}) => (
	<Collapse in={Boolean(trigger)}>
		<Alert
			severity={severity}
			action={
				<IconButton
					aria-label='close'
					size='small'
					onClick={onClick}
				>
					<Close />
				</IconButton>
			}
		>
			{content}
		</Alert>
	</Collapse>
)
