import {TableRow as MuiTableRow} from '@mui/material'

export default ({
	children,
	sx,
	tight,
	...props
}) => (
	<MuiTableRow
		sx={{
			...sx,
			"& > .MuiTableCell-root": {
				border: 'none',
				...tight && {py: '2px'}
			}
		}}
		{...props}
	>
		{children}
	</MuiTableRow>
)
