import {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {setOpen} from 'providers/slices/sidebar'

import useSignOut from 'functions/useSignOut'
import packageJson from 'packageJson'

import {
	AppBar as MuiAppBar,
	Avatar,
	Divider,
	IconButton,
	Menu,
	MenuItem,
	ListItemIcon,
	ListItemText,
	Toolbar,
	Typography,
	useMediaQuery,
	useTheme
} from '@mui/material'

import {
	Code,
	Logout,
	Menu as MenuIcon
} from '@mui/icons-material'


const AvatarMenu = () => {
	const [anchorEl, setAnchorEl] = useState(null)
	const handleAvatarClick = e => setAnchorEl(e.currentTarget)
	const handleAvatarClose = _ => setAnchorEl(null)
	const theme = useTheme()
	const signOut = useSignOut()
	const smallViewport = useMediaQuery(theme.breakpoints.only('xs'))
	const userData = useSelector(state => state.userData.userData)

	const Item = ({
		icon,
		label,
		...props
	}) => (
		<MenuItem {...props}>
			{icon && (
				<ListItemIcon>
					{icon}
				</ListItemIcon>
			)}
			<ListItemText>
				{label}
			</ListItemText>
		</MenuItem>
	)


	return (<>
		<IconButton onClick={handleAvatarClick} size='small'>
			<Avatar
				alt={userData?.name}
				src={userData?.picture}
			/>
		</IconButton>
		<Menu
			anchorEl={anchorEl}
			open={!!anchorEl}
			onClose={handleAvatarClose}
			onClick={handleAvatarClose}
			PaperProps={{
				elevation: 6
			}}
		>
			{smallViewport && ([
				<Item
					dense
					disabled
					icon={(
						<Avatar
							alt={userData?.name}
							src={userData?.picture}
							sx={{
								height: '20px',
								width: '20px'
							}}
						/>
					)}
					label={userData?.name}
					key='avatar'
				/>,
				<Divider key='divider' />
			])}

			<Item
				dense
				disabled
				icon={<Code />}
				label={`v${packageJson.version}`}
			/>

			<Item
				dense
				icon={<Logout />}
				label='Logout'
				onClick={signOut}
			/>
		</Menu>
	</>)
}


export default ({
	sx,
	...props
}) => {
	const theme = useTheme()
	const smallViewport = useMediaQuery(theme.breakpoints.down('md'))
	const dispatch = useDispatch()
	const pageTitle = useSelector(state => state.pageTitle)
	const drawerOpen = useSelector(state => state.sidebar.open)
	const user = useSelector(state => state.auth.user)
	const openDrawer = () => dispatch(setOpen(true))

	return (
		<MuiAppBar
			id='AppBar'
			position='fixed'
			open={drawerOpen}
			
			sx={{
				background: theme.palette.background.paper,
				boxShadow: 'none',
				transition: theme.transitions.create([
					'width',
					'margin'
				], {
					easing: theme.transitions.easing.sharp,
					duration: drawerOpen ? theme.transitions.duration.enteringScreen : theme.transitions.duration.leavingScreen
				}),
				...theme.mixins.toolbar,
				...sx
			}}
			{...props}
		>

			<Toolbar>
				{!drawerOpen && (
					<IconButton
						edge='start'
						aria-label='open drawer'
						onClick={openDrawer}
					>
						<MenuIcon />
					</IconButton>
				)}

				<Typography
					variant='h3'
					component='h1'
					noWrap
					sx={{
						flexGrow: 1,
						ml: theme.spacing((!smallViewport && drawerOpen) ? 28 : 2),
						transition: theme.transitions.create('margin', {
							easing: theme.transitions.easing.sharp,
							duration: theme.transitions.duration.enteringScreen
						})
					}}
				>
					{pageTitle || document.title || '\u200a'}
				</Typography>

				{!smallViewport && (
					<Typography
						variant='subtitle1'
						noWrap
						sx={{
							mr: 1,
							flexGrow: 1,
							textAlign: 'right'
						}}
					>
						{user.name}
					</Typography>
				)}

				<AvatarMenu />
			</Toolbar>
		</MuiAppBar>
	)
}