import {useState, useEffect, forwardRef} from 'react'
import axios from 'axios'
import {api} from 'functions/axios'
import {useDispatch, useSelector} from 'react-redux'
import {setOpen} from 'providers/slices/sidebar'

import {
	Alert,
	AlertTitle,
	Avatar,
	Box,
	Button,
	ButtonGroup,
	Chip,
	Paper,
	FormControl,
	TextField,
	ToggleButton as MuiToggleButton,
	useTheme
} from '@mui/material'
import * as Mui from '@mui/material'
import * as Icons from '@mui/icons-material'

import {db} from 'providers/firebase.js'
import {ref, update, set, onValue} from 'firebase/database'
import {permissionsObject} from 'data/permissions'

const CustomChip = ({
	name,
	time,
	...props
 }) => {
	return (
		<Chip
			label={(
				<Box sx={{
					display: 'flex',
					gap: .5
				}}>
					<Box>
						{name}
					</Box>
					<Box sx={{
						backgroundColor: `rgba(255, 255, 255, 0.7)`,
						borderRadius: 2,
						px: .5
					}}>
						{time}
					</Box>
				</Box>
			)}
			{...props}
		>

		</Chip>
	)
}


const updatePermissions = () => {
	set(ref(db, '/permissions'), permissionsObject)
}


export default () => {
	const theme = useTheme()
	const dispatch = useDispatch()

	return (
		<Box sx={{
			height: '100%',
			overflow: 'hidden',
			display: 'flex',
			flexDirection: 'column',
			gap: 2,
		}}>
			<Box>
				<Button onClick={updatePermissions}>
					Update Permissions
				</Button>
			</Box>

			<Box>
				<CustomChip
					avatar={<Avatar>J</Avatar>}
					label={(
						<Box sx={{
							display: 'flex',
							gap: .5
						}}>
							<Box>
								Jack
							</Box>
							<Box sx={{
								backgroundColor: '#666',
								borderRadius: 2,
								px: .5
							}}>
								18:00
							</Box>
						</Box>
					)}
				/>
			</Box>

			<Box>
				<Button onClick={() => {
					dispatch(setOpen(true))
				}}>
					open
				</Button>
				<Button onClick={() => {
					dispatch(setOpen(false))
				}}>
					close
				</Button>
			</Box>

			<Box sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: 1
			}}>

				<Alert
					action={<Button color='inherit' size='small'>Action</Button>}
					severity='info'
				>
					<AlertTitle>info</AlertTitle>
				</Alert>
				
				<Alert
					action={<Button color='inherit' size='small'>Action</Button>}
					severity='success'
				>
					<AlertTitle>success</AlertTitle>
				</Alert>
				
				<Alert
					action={<Button color='inherit' size='small'>Action</Button>}
					severity='warning'
				>
					<AlertTitle>warning</AlertTitle>
				</Alert>
				
				<Alert
					action={<Button color='inherit' size='small'>Action</Button>}
					severity='error'
				>
					<AlertTitle>error</AlertTitle>
				</Alert>
				
				<Alert
					action={<Button color='inherit' size='small'>Action</Button>}
					severity='info'
					variant='outlined'
				>
					<AlertTitle>info</AlertTitle>
				</Alert>
				
				<Alert
					action={<Button color='inherit' size='small'>Action</Button>}
					severity='success'
					variant='outlined'
				>
					<AlertTitle>success</AlertTitle>
				</Alert>
				
				<Alert
					action={<Button color='inherit' size='small'>Action</Button>}
					severity='warning'
					variant='outlined'
				>
					<AlertTitle>warning</AlertTitle>
				</Alert>
				
				<Alert
					action={<Button color='inherit' size='small'>Action</Button>}
					severity='error'
					variant='outlined'
				>
					<AlertTitle>error</AlertTitle>
				</Alert>
				
				<Alert
					action={<Button color='inherit' size='small'>Action</Button>}
					severity='info'
					variant='filled'
				>
					<AlertTitle>info</AlertTitle>
				</Alert>
				
				<Alert
					action={<Button color='inherit' size='small'>Action</Button>}
					severity='success'
					variant='filled'
				>
					<AlertTitle>success</AlertTitle>
				</Alert>
				
				<Alert
					action={<Button color='inherit' size='small'>Action</Button>}
					severity='warning'
					variant='filled'
				>
					<AlertTitle>warning</AlertTitle>
				</Alert>
				
				<Alert
					action={<Button color='inherit' size='small'>Action</Button>}
					severity='error'
					variant='filled'
				>
					<AlertTitle>error</AlertTitle>
				</Alert>
				

			</Box>

		</Box>
	)
}