import {useSelector} from 'react-redux'
import {eventStatuses, eventTypes} from 'data/eventOptions'
import EventStatusIcon from 'components/EventStatusIcon'
import LinkButton from 'components/LinkButton'

import CollapsibleCard from 'components/CollapsibleCard'

import {
	Box,
	CardContent,
	CardActions,
	Divider,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Skeleton
} from '@mui/material'


export default () => {
	const {events, loading} = useSelector(state => state.events)

	return (
		<CollapsibleCard
			variant='outlined'
			title='Upcoming Events'
			id='upcomingEvents'
			subtitle={
				loading ? (
					<Skeleton />
				) : (
					`${events.filter(event => event.status !== 'cancelled').length} events`
				)
			}
			sx={{p: 2}}
		>
			<CardContent>
				<List dense>
					{eventStatuses.map(({name, slug}) => (
						<ListItem
							key={slug}
							secondaryAction={
								loading ? (
									<Skeleton width={'1rem'} />
								) : (
									events.filter(event => event.status === slug)?.length
								)
							}
							sx={{
								py: .25
							}}
						>
							<ListItemAvatar>
								{loading ? (
									<Skeleton variant='circular' height={20} width={20} />
								) : (
									<EventStatusIcon
										type={slug}
										tooltip
									/>
								)}
							</ListItemAvatar>
							<ListItemText style={{verticalAlign: 'middle'}}>
								{loading ? (
									<Skeleton />
								) : (
									name
								)}
							</ListItemText>
						</ListItem>
					))}
				</List>
				<Divider sx={{my: 2}} />
				<Box>
					<List dense>
						{eventTypes.map(({name, slug, Icon}) => (
							<ListItem
								key={slug}
								secondaryAction={
									loading ? (
										<Skeleton width={'1rem'} />
									) : (
										events.filter(event => event.type === slug)?.length
									)
								}
								sx={{
									py: .25
								}}
							>
								<ListItemAvatar>
									{loading ? (
										<Skeleton variant='circular' height={20} width={20} />
									) : (
										<Icon
											fontSize='small'
											style={{
												verticalAlign: 'middle'
											}}
										/>
									)}
								</ListItemAvatar>
								<ListItemText style={{verticalAlign: 'middle'}}>
									{loading ? (
										<Skeleton />
									) : (
										name
									)}
								</ListItemText>
							</ListItem>
						))}
					</List>
				</Box>
			</CardContent>

			<CardActions sx={{
				pt: 0,
				justifyContent: 'space-between',
				mt: 'auto'
			}}>
				<LinkButton
					fullWidth
					href='/events'
					title='View All Events'
				/>
			</CardActions>
		</CollapsibleCard>
	)
}

