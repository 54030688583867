import {useState, useEffect} from 'react'
import {useSelector} from 'react-redux'
import {useTheme} from '@mui/material/styles'

import {
	Avatar,
	Box,
	Button,
	Checkbox,
	Collapse,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormLabel,
	IconButton,
	Paper,
	Typography,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip,
	Unstable_Grid2 as Grid
} from '@mui/material'

import {
	ExpandLess,
	ExpandMore,
	FiberNew,
	InfoOutlined,
	Square
} from '@mui/icons-material'

import Notification from 'components/Notification'

import {db} from 'providers/firebase'
import {ref, update, onValue} from 'firebase/database'
import {blank} from 'functions/blank.js'
import {permissions, permissionsByModule} from 'data/permissions'
import modules from 'data/modules'


const createRow = ({
	name,
	email,
	picture,
	permissions = {},
	uid
}) => ({
	avatar: picture,
	email,
	id: uid,
	name,
	permissions,
	uid
})


const Row = ({user, children}) => {
	const [open, setOpen] = useState(false)
	const row = createRow(user)

	return (<>
		<TableRow
			id={row.uid}
			sx={{
				'& > .MuiTableCell-root': {
					borderBottom: 'unset'
				}
			}}
		>
			<TableCell>
				<Avatar
					alt={row.name}
					src={row.avatar}
				/>
			</TableCell>
			
			<TableCell
				component='th'
				scope='row'
			>
				{row.name}{user.newUser && (
					<span style={{
						marginLeft: 8,
						verticalAlign: 'middle'
					}}>
						<FiberNew />
					</span>
			)}
			</TableCell>
			
			<TableCell>
				{row.email}
			</TableCell>
			
			<TableCell>
				<IconButton
					aria-label='expand row'
					size='small'
					onClick={() => setOpen(!open)}
				>
					{open ? <ExpandLess /> : <ExpandMore />}
				</IconButton>
			</TableCell>
		</TableRow>
		<TableRow>
			<TableCell
				colSpan={4}
				sx={{p: 0}}
			>
				<Collapse
					in={open}
					timeout='auto'
				>
					<Box sx={{overflow: 'hidden'}}>
						{children}
					</Box>
				</Collapse>
			</TableCell>
		</TableRow>
	</>)
}



const checkboxColours = {
	manage: 'secondary',
	sensitive: 'warning',
	super: 'error',
	view: 'primary'
}


const LegendItem = ({
	color,
	label,
	...props
}) => (
	<Grid sx={{
		display: 'flex',
		alignItems: 'flex-end',
		gap: .5
	}} {...props}>
		<Square color={color} />
		<Typography variant='body2'>{label}</Typography>
	</Grid>
)


const PermissionsPanel = ({user}) => {
	const [userPermissions, setUserPermissions] = useState({})
	const [initialValues, setInitialValues] = useState({})
	const [changed, setChanged] = useState(false)
	const [result, setResult] = useState({})
	const verifiedUser = useSelector(state => state.auth.verifiedUser)

	const userCanManageUsers = verifiedUser.permissions.includes('manageUsers')

	useEffect(() => {
		const userPerms = permissions.reduce((acc, {slug}) => ({
			...acc,
			[slug]: !!user.permissions?.[slug]
		}), {})

		setUserPermissions(userPerms)
		setInitialValues({...userPerms})
	}, [])

	useEffect(() => {
		const hasChanged = !Object.keys(initialValues).every(key => initialValues[key] === userPermissions[key])
		setChanged(!!hasChanged)
		if (hasChanged) {
			setResult({})
		}
	}, [userPermissions, initialValues])


	const handleChange = slug => e => {
		setUserPermissions(prev => ({
			...prev,
			[slug]: !!!userPermissions?.[slug]
		}))
	}

	const saveChanges = () => {
		setResult({})
		update(ref(db, `/users/${user.uid}/permissions`), userPermissions).then(() => {
			if (user.newUser) {
				update(ref(db, `/users/${user.uid}`), {
					newUser: false
				})
			}
			setInitialValues(userPermissions)
			setResult({
				type: 'success',
				message: `Permissions for ${user.name} updated`
			})
		}).catch(e => {
			console.error(e)
			setResult({
				type: 'error',
				message: (e.message || e)
			})
		})
	}

	const resetChanges = () => setUserPermissions(initialValues)

	return (
		<Box
			component={Paper}
			sx={{
				display: 'flex',
				flexDirection: 'column',
				padding: 2,
				gap: 2
			}}
		>
			<Typography variant='subtitle1'>Permissions</Typography>
			<Grid container>
				{permissionsByModule.map(({
					module,
					permissions
				}) => (
					<Grid
						key={`module-${module}`}
						xs={12}
						sm={6}
						lg={4}
						xl={3}
					>
						<FormControl
							component='fieldset'
							variant='standard'
						>
							<FormLabel
								component='legend'
								focused={false}
							>
								{modules[module].label}
							</FormLabel>
							<FormGroup>
								{permissions
									.filter(({slug}) => !['admin', 'testing'].includes(slug))
									.sort((a, b) => a.type.localeCompare(b.type))
									.map(({
										description,
										label,
										slug,
										type
									}) => (
										<Box
											key={`permission-${slug}`}
											sx={{display: 'flex'}}
										>
											<FormControlLabel
												componentsProps={{
													typography: {
														variant: 'subtitle2'
													}
												}}
												control={
													<Checkbox
														checked={!!userPermissions?.[slug]}
														color={checkboxColours[type]}
														disabled={!userCanManageUsers}
														onChange={handleChange(slug)}
													/>
												}
												label={label}
												sx={{
													mr: .5
												}}
											/>
											<Tooltip
												arrow
												placement='top'
												title={description}
											>
												<IconButton size='small'>
													<InfoOutlined fontSize='small' />
												</IconButton>
											</Tooltip>
										</Box>
									))
								}
							</FormGroup>
						</FormControl>
					</Grid>
				))}
			</Grid>

			{userCanManageUsers && (<>
				<Box sx={{
					display: 'flex',
					flexWrap: 'wrap',
					justifyContent: 'space-between',
					gap: 2
				}}>
					<Grid
						alignItems='center'
						container
						direction='row'
						spacing={2}
					>
						<LegendItem
							color='primary'
							label='Read-only access'
						/>
						<LegendItem
							color='secondary'
							label='Edit access'
						/>
						<LegendItem
							color='warning'
							label='Sensitive information'
						/>
						<LegendItem
							color='error'
							label='Super'
						/>
					</Grid>

					{changed && (
						<Box sx={{
							display: 'flex',
							gap: 1
						}}>
							<Button onClick={saveChanges}>Save</Button>
							<Button onClick={resetChanges}>Reset</Button>
						</Box>
					)}
				</Box>

				<Box>
					<Notification
						trigger={result.type}
						severity={result.type}
						content={result.message}
						onClick={() => setResult({})}
					/>
				</Box>
			</>)}
		</Box>
	)
}





export default () => {
	const [userData, setUserData] = useState([])
	const theme = useTheme()

	useEffect(() => {
		const usersRef = ref(db, '/users')
		onValue(usersRef, snapshot => {
			setUserData(Object.values(snapshot.val() ?? {}))
		})
	}, [])

	return (
		<Box>
			<TableContainer>
				<Table
					stickyHeader
					size='small'
					sx={{
						border: `2px solid ${theme.palette.divider}`
					}}
				>
					<TableHead>
						<TableRow>
							<TableCell sx={{width: '40px'}}>{blank}</TableCell>
							<TableCell>Name</TableCell>
							<TableCell>E-mail</TableCell>
							<TableCell sx={{width: '40px'}}>{blank}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{userData.map(user => (
							<Row
								key={user.uid}
								user={user}
							>
								<PermissionsPanel user={user} />
							</Row>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	)
}