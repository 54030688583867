export const googleCalendarColours = {
	lavender: 1,
	sage: 2,
	grape: 3,
	flamingo: 4,
	banana: 5,
	tangerine: 6,
	peacock: 7,
	graphite: 8,
	blueberry: 9,
	basil: 10,
	tomato: 11
}

export const colours = {
	success: {
		hex: '#1cbf58',
		dec: 1883992
	},
	error: {
		hex: '#cc0000',
		dec: 13369344
	},
	warn: {
		hex: '#bf581c',
		dec: 12539932
	},
	tlr: {
		hex: '#1c58bf',
		dec: 1857727,
		calendar: googleCalendarColours.blueberry
	}
}

export const success = colours.success
export const error = colours.error
export const warn = colours.warn
export const tlr = colours.tlr

export const eventStatusColours = {
	onSale: {
		hex: '#73bc73',
		dec: 7584883,
		calendar: googleCalendarColours.sage,
		contrastText: '#000000'
	},
	soldOut: {
		hex: '#167016',
		dec: 1470486,
		calendar: googleCalendarColours.basil,
		contrastText: '#ffffff'
	},
	postponed: {
		hex: '#ffc05d',
		dec: 16741492,
		calendar: googleCalendarColours.flamingo,
		contrastText: '#000000'
	},
	cancelled: {
		hex: '#ff7474',
		dec: 16760925,
		calendar: googleCalendarColours.tomato,
		contrastText: '#000000'
	},
	notAnnounced: {
		hex: '#e5e485',
		dec: 15066245,
		calendar: googleCalendarColours.banana,
		contrastText: '#000000'
	}
}