import {useController} from 'react-hook-form'
import {useState, useEffect} from 'react'
import {api} from 'functions/axios'

import {
	Box,
	IconButton,
	Paper,
	Input
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import {Clear} from '@mui/icons-material'

import Tooltip from 'components/events/Tooltip'
import Alert from 'components/events/Alert'
import {allowedFileTypes} from 'data/eventOptions'

const ImagePreview = ({
	previewImage,
	removePreviewImage,
	disabled,
}) => (
	<Paper
		elevation={1}
		sx={{
			mb: 2,
			position: 'relative',
			minHeight: '46px'
		}}
	>
		<img
			src={previewImage}
			style={{
				display: 'block',
				maxWidth: '100%',
				borderRadius: '4px',
				margin: '0 auto'
			}}
			alt='Upload'
		/>
		<Tooltip title='Remove image'>
			<span>
				<IconButton
					sx={{
						position: 'absolute',
						top: '4px',
						right: '4px',
						background: 'rgba(0, 0, 0, .5)'
					}}
					disabled={disabled}
					onClick={removePreviewImage}
				>
					<Clear />
				</IconButton>
			</span>
		</Tooltip>
	</Paper>
)


const ImageUpload = ({
	name,
	control,
	error,
	setError,
	clearErrors,
	uid = null,
	hasImage = false,
	...props
}) => {
	const {
		field: {
			value,
			onChange,
			...field
		},
		
	} = useController({
		name,
		control
	})
	const imageRemovedController = useController({name: 'imageRemoved', control})
	const [previewImage, setPreviewImage] = useState(null)
	const [progress, setProgress] = useState(0)
	const [imageChanged, setImageChanged] = useState(false)
	const loading = (progress > 0 && progress < 100)

	const removePreviewImage = () => {
		setPreviewImage(null)
		onChange(null)
		document.getElementById('eventImage').value = null
	}

	const loadPreviewImage = blob => {
		try {
			const reader = new FileReader()
			reader.readAsDataURL(blob)
			reader.onprogress = e => setProgress(Math.floor(e.loaded.total*100))
			reader.onload = e => {
				console.log(reader)
				setPreviewImage(reader.result)
			}
			reader.onerror = e => {
				setProgress(0)
				throw(reader.error)
			}
		} catch (e) {
			removePreviewImage()
			console.error('error loading image', e)
			setError(name, {
				type: 'custom',
				message: e
			})
		}
	}

	useEffect(() => {
		imageRemovedController.field.onChange(!previewImage)
	}, [previewImage])

	useEffect(() => {
		if (!hasImage || !uid || imageChanged) {return}
		const getImageFromAPI = async () => {
			try {
				const image = await api({
					url: `/events/image/${uid}`,
					responseType: 'blob'
				})
				loadPreviewImage(image.data)
			} catch (e) {
				console.error('couldn\'t get image', e)
				setError(name, {
					type: 'custom',
					message: `Error whilst retrieving image: ${e}`
				})
			}
		}
		getImageFromAPI()
	}, [hasImage, uid, imageChanged])


	const handleImageSelect = e => {
		clearErrors(name)
		if (!e.target?.files || e.target.files.length === 0) {
			// user didn't select a file
			return
		}
		
		const file = e.target.files[0]
		if (!allowedFileTypes.includes(file.type)) {
			setError(name, {
				type: 'custom',
				message: `Invalid file type. Supported file types: ${allowedFileTypes.join(', ')}`
			})
			return
		}

		if (file.size > (1024*1024*20)) {
			setError(name, {
				type: 'custom',
				message: 'Maximum file size: 20mb'
			})
			return
		}

		loadPreviewImage(file)
		onChange(e.target.files[0])
		setImageChanged(true)
	}

	return (
		<Box>
			{(previewImage && !error) && (
				<ImagePreview
					previewImage={previewImage}
					removePreviewImage={removePreviewImage}
					disabled={props.disabled}
				/>
			)}
			<LoadingButton
				component='label'
				variant='contained'
				loading={loading}
				{...props}
			>
				<Input
					{...field}
					type='file'
					name='image'
					id='eventImage'
					onChange={handleImageSelect}
					inputProps={{
						hidden: true,
						accept: allowedFileTypes
					}}
					sx={{
						display: 'none'
					}}
				/>
				<Box>
					{previewImage ? 'Select new image' : 'Select image'}
				</Box>
			</LoadingButton>

			<input
				{...imageRemovedController.field}
				name='imageRemoved'
				type='hidden'
				// value={!!previewImage}
			/>

			{error?.message && (
				<Alert sx={{
					mt: 2
				}}>
					{error.message}
				</Alert>
			)}

		</Box>
	)
}

export default ImageUpload