import React from 'react'
import ReactDOM from 'react-dom/client'
import '@fontsource/nunito'
import '@fontsource/lexend'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import {Provider} from 'react-redux'
import store from 'providers/store'


const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
	<React.StrictMode>
		<Provider store={store}>
			<App />
		</Provider>
	</React.StrictMode>
)

reportWebVitals(process.env.NODE_ENV === 'development' && console.log)