import {useEffect} from 'react'
import {db} from 'providers/firebase'
import {ref, onValue} from 'firebase/database'
import {useDispatch, useSelector} from 'react-redux'
import {
	setUserData,
	clearUserData,
	setError,
	setLoading
} from 'providers/slices/userData'

export default () => {
	const dispatch = useDispatch()
	const {loading} = useSelector(state => state.loading)
	const {
		loading: authLoading,
		verifiedUser
	 } = useSelector(state => state.auth)

	useEffect(() => {
		if (loading || authLoading) {return}
		if (!authLoading) {dispatch(setLoading(true))}
		if (verifiedUser?.uid) {
			const userDataRef = ref(db, `/users/${verifiedUser.uid}`)
			onValue(userDataRef, snapshot => {
				console.log('[userData]', snapshot.val())
				dispatch(setUserData(snapshot.val()))
			}, error => {
				setError(error)
			})
		} else {
			dispatch(clearUserData())
		}
	}, [authLoading, loading, verifiedUser])
}
