import {useState, useEffect, useMemo} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Link, useParams} from 'wouter'
import {setPageTitle} from 'providers/slices/pageTitle'
import {getAuth} from 'providers/firebase'
import {api, apiUrl} from 'functions/axios'
import {formatDateTime, formatTime} from 'functions/formatDates'
import formatPrice from 'functions/formatPrice'
import {eventTypes, eventStatuses, rooms, ageRestrictions} from 'data/eventOptions'
import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Box,
	Collapse,
	Divider,
	Grid,
	IconButton,
	Link as MuiLink,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	TableContainer,
	Table as MuiTable,
	TableBody,
	TableHead,
	Tooltip,
	Typography,
	useMediaQuery,
	useTheme
} from '@mui/material'
import TableCell, {tableCellClasses} from '@mui/material/TableCell'
import TableRow, {tableRowClasses} from '@mui/material/TableRow'
import {
	ArticleOutlined,
	ConfirmationNumberOutlined,
	ConfirmationNumber as ConfirmationNumberIcon,
	ContactMailOutlined,
	Edit,
	EventNoteOutlined,
	ExpandLess,
	ExpandMore,
	KeyboardArrowDown,
	HistoryOutlined,
	ImageOutlined,
	InfoOutlined,
	LinkOutlined,
	OndemandVideoOutlined,
	Web
} from '@mui/icons-material'
import {Icon} from '@iconify/react'

import Error from 'pages/Error'
import Loading from 'components/Loading'
import StickySidebar from 'components/events/StickySidebar'
import Section from 'components/events/Section'
import HeadlineArtistDetails from 'components/events/HeadlineArtistDetails'
import CollapsibleSection from 'components/CollapsibleSection'
import ListItemLabel from 'components/events/ListItemLabel'
import EventStatusIcon from 'components/EventStatusIcon'
import Image from 'components/Image'
import LinkButton from 'components/LinkButton'
import YoutubeVideoEmbed from 'components/YoutubeEmbed'
import TicketSalesChart from 'components/TicketSalesChart'
import CheckPermission from 'functions/CheckPermission'
import {formItems} from 'components/events/defaultValues'
import LastUpdatedValue from 'components/events/LastUpdatedValue'
import Th from 'components/Th'


const Table = ({children, ...props}) => (
	<TableContainer>
		<MuiTable
			sx={{
				[`& .${tableCellClasses.root}`]: {
					borderBottom: 'none'
				},
				[`& .${tableRowClasses.root} .${tableCellClasses.root}:first-of-type`]: {
					minWidth: '150px'
				},
				display: 'table-cell',
				wordBreak: 'break-word'
			}}
			size='small'
			{...props}
		>
			{children}
		</MuiTable>
	</TableContainer>
)


const Oblique = ({
	children,
	sx,
	variant = 'body2',
	...props
}) => (
	<Typography
		variant={variant}
		sx={{
			fontStyle: 'oblique',
			...sx
		}}
		{...props}
	>
		{children}
	</Typography>
)


const EventExternalLinks = ({event, ...props}) => {
	const links = [{
		slug: 'calendar',
		icon: <Icon icon='logos:google-calendar' height='24' />,
		label: 'Calendar',
		href: event.links?.calendar
	}, {
		slug: 'drive',
		icon: <Icon icon='logos:google-drive' height='24' />,
		label: 'Drive',
		href: event.links?.drive
	}, {
		slug: 'tickets',
		icon: <Icon icon='noto:ticket' height='24' />,
		label: 'Tickets',
		href: event.ticketUrl
	}, {
		slug: 'website',
		icon: <Icon icon='iconoir:www' height='24' />,
		label: 'theliverooms.com',
		href: event.links?.website
	}, {
		slug: 'boxOfficeSheet',
		icon: <Icon icon='mdi:file-document' color='#2684fc' height='24' />,
		label: 'Box Office Sheet',
		href: event.google?.boxOfficeSheet?.url
	}]

	return (
		<Grid container spacing={2} {...props} component={List}>
			{links.map(({slug, icon, label, href}) => (
				<Grid item xs={12} sm={6} md={4} lg={4} xl={3} key={slug}>
					<ListItem
						disablePadding
						dense
					>
						<ListItemButton
							href={href}
							disabled={!href}
							target='_blank'
							rel='noreferrer'
						>
							<ListItemIcon>
								{icon}
							</ListItemIcon>
							<ListItemText
								id={slug}
								primary={label}
								primaryTypographyProps={{
									size: 'small'
								}}
								sx={{
									wordBreak: 'break-word'
								}}
							/>
						</ListItemButton>
					</ListItem>
				</Grid>
			))}
		</Grid>
	)
}


const sortedSupportArtists = (supportArtists = []) => (
	[...supportArtists].sort((a, b) => {
		if (a.start) {
			return b.start ? (b.start - a.start) : -1
		} else {
			return b.start ? 1 : (a.index - b.index)
		}
	})
)


const UpdateRow = ({
	field,
	value
}) => {
	let formattedValue
	switch (field) {

		case 'ageRestriction': {
			formattedValue = ageRestrictions.find(x => x.slug === value)?.name || value
			break
		}

		case 'start': {
			formattedValue = formatDateTime(value)
			break
		}

		case 'end':
		case 'headlineArtistStartTime': {
			formattedValue = formatTime(value)
			break
		}

		case 'externalContact': {
			formattedValue = (
				<Box>
					<Typography variant='body2'>
						Name: {value?.name}
					</Typography>
					<Typography variant='body2'>
						Promoter: {value?.promoter}
					</Typography>
					<Typography variant='body2'>
						Email: {value?.email}
					</Typography>
				</Box>
			)
			break
		}


		case 'headlineArtist': {
			formattedValue = value?.id ? (
				<MuiLink
					href={`https://wikidata.org/w/index.php?search=${value.id}`}
					target='_blank'
					rel='noreferrer'
					sx={{
						textDecoration: 'none'
					}}
				>
					{value?.name}
				</MuiLink>
			) : (
				value?.name
			)
			break
		}

		// case 'image': {

		// 	break
		// }

		// case 'imageRemoved': {

		// 	break
		// }

		case 'prices': {
			formattedValue = (
				<Box>
					{value.ticket && <Typography variant='body2'>Ticket: £{value.ticket}</Typography>}
					{value.door && <Typography variant='body2'>Door: £{value.door}</Typography>}
				</Box>
			)
			break
		}

		case 'room': {
			formattedValue = rooms.find(x => x.slug === value)?.name || value
			break
		}

		case 'status': {
			formattedValue = eventStatuses.find(x => x.slug === value)?.name || value
			break
		}

		case 'supportArtists': {
			formattedValue = (
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Name</TableCell>
							<TableCell>Start</TableCell>
							<TableCell>End</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{sortedSupportArtists(value).map(({name, start, end}, i) => (
							<TableRow key={`${start}-${i}`}>
								<TableCell>{name}</TableCell>
								<TableCell>{start ? formatTime(start) : ''}</TableCell>
								<TableCell>{end ? formatTime(end) : ''}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			)
			break
		}


		case 'ticketUrl': {
			formattedValue = (
				<MuiLink
					href={value}
					target='_blank'
					rel='noreferrer'
					sx={{
						textDecoration: 'none'
					}}
				>
					{value}
				</MuiLink>
			)
			break
		}

		case 'type': {
			formattedValue = eventTypes.find(x => x.slug === value)?.name || value
			break
		}

		case 'youtubeId': {
			formattedValue = <YoutubeVideoEmbed id={value} />
			break
		}

		default: {
			formattedValue = typeof value === 'string' ? value : JSON.stringify(value)
			break
		}
	}

	return (
		<TableRow>
			<TableCell>
				{formItems[field] || field}
			</TableCell>
			<TableCell>
				{formattedValue}
			</TableCell>
		</TableRow>
	)
}


const SectionLink = ({
	disabled,
	icon,
	name,
	href,
	sx,
	...props
}) => {
	const theme = useTheme()
	const smallViewport = useMediaQuery(theme.breakpoints.down('md'))

	const handleScroll = targetId => event => {
		event.preventDefault()
		const targetSection = document.getElementById(targetId.charAt(0) === '#' ? targetId.slice(1) : targetId)

		if (targetSection) {
			window.scrollTo({
				top: (targetSection.getBoundingClientRect().top + window.scrollY) - (smallViewport ? 72 : 80),
				left: 0,
				behavior: 'smooth'
			})
		}
	}


	const LinkButton = ({children}) => {
		return href.charAt(0) === '#' ? (
			<ListItemButton
				disabled={disabled}
				href={href}
				onClick={handleScroll(href)}
				{...props}
			>
				{children}
			</ListItemButton>
		) : (
			<ListItemButton
				component={Link}
				disabled={disabled}
				to={href}
				{...props}
			>
				{children}
			</ListItemButton>
		)
	}


	const Item = props => {
		return (
			<ListItem
				disablePadding
				{...props}
			>
				<LinkButton
					href={href}
					onClick={handleScroll(href)}
				>
					{icon && (
						<ListItemIcon sx={{minWidth: '42px'}}>
							{icon}
						</ListItemIcon>
					)}
					<ListItemLabel
						inset={!icon}
						primary={name}
						sx={{...!icon && {paddingLeft: '42px'}}}
					/>
				</LinkButton>
			</ListItem>
		)
	}

	return smallViewport ? (
		<Tooltip
			arrow
			placement='right'
			title={name}
		>
			<span>
				<Item />
			</span>
		</Tooltip>
	) : (
		<Item />
	)
}


const SectionLinkNested = ({
	icon,
	items = [],
	name,
	target,
	sx,
	...props
}) => {
	const [expanded, setExpanded] = useState(true)
	const toggleExpanded = () => setExpanded(!expanded)

	return (<>
		<ListItem
			disablePadding
			secondaryAction={(
				<IconButton
					size='small'
					onClick={toggleExpanded}
				>
					{expanded ? <ExpandLess /> : <ExpandMore />}
				</IconButton>
			)}
			{...props}
		>
			<ListItemButton>
				{icon && (
					<ListItemIcon sx={{minWidth: '42px'}}>
						{icon}
					</ListItemIcon>
				)}
				<ListItemLabel
					inset={!icon}
					primary={name}
					sx={{...!icon && {paddingLeft: '42px'}}}
				/>
			</ListItemButton>
		</ListItem>
		<Collapse
			in={expanded}
			timeout='auto'
		>
			<List component='div' disablePadding>
				{items.map(({
					href,
					icon,
					name,
					slug
				}) => (
					<ListItem
						dense
						disablePadding
						key={slug}
						sx={{pl: 2}}
					>
						<ListItemButton
							disabled={!href}
							href={href}
							target='_blank'
							rel='noreferrer'
						>
							{icon && (
								<ListItemIcon sx={{minWidth: '36px'}}>
									{icon}
								</ListItemIcon>
							)}
							<ListItemLabel
								inset={!icon}
								primary={name}
								sx={{...!icon && {paddingLeft: '42px'}}}
							/>
						</ListItemButton>
					</ListItem>
				))}
			</List>
		</Collapse>
	</>)
}


const ViewEvent = ({event}) => {
	const theme = useTheme()
	const imageUrl = new URL(`events/image/${event.uid}`, apiUrl)
	const smallViewport = useMediaQuery(theme.breakpoints.down('md'))
	const dispatch = useDispatch()
	useEffect(() => {
		if (event) {
			dispatch(setPageTitle(event.name))
		}
	}, [event])

	const externalLinks = [{
		slug: 'calendar',
		icon: <Icon icon='logos:google-calendar' height='16' />,
		name: 'Calendar',
		href: event.links?.calendar || ''
	}, {
		slug: 'drive',
		icon: <Icon icon='logos:google-drive' height='16' />,
		name: 'Drive',
		href: event.links?.drive || ''
	}, {
		slug: 'tickets',
		icon: <ConfirmationNumberOutlined fontSize='small' />,
		name: 'Tickets',
		href: event.ticketUrl || ''
	}, {
		slug: 'website',
		icon: <Icon icon='iconoir:www' height='16' />,
		name: 'theliverooms.com',
		href: event.links?.website || ''
	}, {
		slug: 'boxOfficeSheet',
		icon: <Icon icon='mdi:file-document' color='#2684fc' height='16' />,
		name: 'Box Office Sheet',
		href: event.google?.boxOfficeSheet?.url || ''
	}]

	const eventHasPassed = (event.end || event.start) < Date.now()

	return (<>
		<Box sx={{
			display: 'flex',
			alignItems: 'flex-start',
			gap: {
				xs: 1,
				md: 2
			}
		}}>
			<StickySidebar>
				<SectionLink
					icon={<EventNoteOutlined fontSize='small' />}
					name='Basic Information'
					href='#basicInformation'
				/>
				<SectionLink
					icon={<Icon icon='mdi:guitar-electric' height='18' />}
					name='Performing Artists'
					href='#artistInformation'
				/>
				<SectionLink
					icon={<ConfirmationNumberOutlined fontSize='small' />}
					name='Ticketing & Entry'
					href='#ticketing'
				/>
				<SectionLink
					icon={<ArticleOutlined fontSize='small' />}
					name='Description'
					href='#description'
				/>
				<SectionLink
					icon={<ImageOutlined fontSize='small' />}
					name='Image'
					href='#eventImage'
				/>
				<SectionLink
					icon={<OndemandVideoOutlined fontSize='small' />}
					name='Video'
					href='#eventVideo'
				/>
				<SectionLink
					icon={<ContactMailOutlined fontSize='small' />}
					name='External Contact'
					href='#externalContact'
				/>
				<SectionLink
					icon={<ConfirmationNumberIcon fontSize='small' />}
					name='Sales'
					href='#sales'
				/>
				<SectionLink
					icon={<HistoryOutlined fontSize='small' />}
					name='Changes'
					href='#changes'
				/>
				{smallViewport ? (
					externalLinks.map(({
						slug,
						icon,
						name,
						href
					}) => (
						<SectionLink
							key={slug}
							icon={icon}
							name={name}
							href={href}
							disabled={!href}
							target='_blank'
						/>
					))
				) : (
					<SectionLinkNested
						icon={<LinkOutlined fontSize='small' />}
						items={externalLinks}
						name='Links'
					/>
				)}
				<CheckPermission permission='manageEvents'>
					<SectionLink
						icon={<Edit fontSize='small' />}
						name='Edit Event'
						href={`/events/${event.uid}/edit`}
						disabled={eventHasPassed}
					/>
				</CheckPermission>
			</StickySidebar>

			<Box sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: {
					xs: 1,
					md: 2
				},
			}}>
				<Section
					hideDescriptions
					id='basicInformation'
					name='Basic Information'
				>
					<Table>
						<TableBody>
							<TableRow>
								<Th>Event name</Th>
								<TableCell>{event?.name}</TableCell>
							</TableRow>
							<TableRow>
								<Th>Website display name</Th>
								<TableCell>{event?.displayName ?? <i>{event?.name}</i>}</TableCell>
							</TableRow>
							<TableRow>
								<Th>Event type</Th>
								<TableCell>
									{eventTypes.find(x => x.slug === event.type)?.name || event.type}
								</TableCell>
							</TableRow>
							<TableRow>
								<Th>Status</Th>
								<TableCell>
									<EventStatusIcon
										type={event.status}
										tooltipPlacement='top'
										sx={{
											mr: 1
										}}
										fontSize='x-small'
									/>
									{eventStatuses.find(x => x.slug === event.status)?.name || event.status}
								</TableCell>
							</TableRow>
							<TableRow>
								<Th>Start</Th>
								<TableCell>
									{formatDateTime(event.start)}
								</TableCell>
							</TableRow>
							<TableRow>
								<Th>End</Th>
								<TableCell>
									{event.end ? formatDateTime(event.end) : <Oblique>No end time set</Oblique>}
								</TableCell>
							</TableRow>
							<TableRow>
								<Th>Room</Th>
								<TableCell>{rooms.find(x => x.slug === event.room)?.name || event.room}</TableCell>
							</TableRow>
						</TableBody>
					</Table>

					<Divider />

					{event.type !== 'private' && (<>
						<Table>
							<TableBody>
								<TableRow>
									<Th>
										Omit from website
									</Th>
									<TableCell>
										{event.hideFromWebsite ? 'Yes' : 'No'}
									</TableCell>
								</TableRow>
								<TableRow>
									<Th>
										Omit from 'Upcoming Events' on website
									</Th>
									<TableCell>
										{event.hideFromUpcoming ? 'Yes' : 'No'}
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>

						<Divider />
					</>)}
					

					<Table>
						<TableBody>
							<TableRow>
								<Th>UID</Th>
								<TableCell>
									{event.uid}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									Last updated
								</TableCell>
								<TableCell>
									{event.updates ? <LastUpdatedValue updates={event.updates} />  : <Oblique>n/a</Oblique>}
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</Section>

				{event.type !== 'private' && (<>
					<Section
						hideDescriptions
						id='artistInformation'
						name='Performing Artists'
					>
						{(event.headlineArtist || event.supportArtists) ? (
							<Box sx={{
								display: 'flex',
								flexDirection: 'column',
								gap: 2
							}}>
								<Table>
									<TableHead>
										<TableRow>
											<TableCell>Name</TableCell>
											<TableCell>Start</TableCell>
											<TableCell>End</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										<TableRow>
											<TableCell>
												{event.headlineArtist?.name || '?'}
											</TableCell>
											<TableCell>
												{event.headlineArtistStartTime ? formatTime(event.headlineArtistStartTime) : '?'}
											</TableCell>
											<TableCell>
												<em>{event.end ? formatTime(event.end) : '?'}</em>
												<Tooltip arrow placement='top' title='Inferred from event end time'>
													<InfoOutlined fontSize='x-small' sx={{ml: .75}} />
												</Tooltip>
											</TableCell>
										</TableRow>
										{Array.isArray(event.supportArtists) && (
											sortedSupportArtists(event.supportArtists).map(({name, start, end}, i) => (
												<TableRow key={`support-${i}`}>
													<TableCell>{name}</TableCell>
													<TableCell>{start ? formatTime(start) : '?'}</TableCell>
													<TableCell>{end ? formatTime(end) : '?'}</TableCell>
												</TableRow>
											))
										)}
									</TableBody>
								</Table>
								{Object.entries(event.headlineArtist || {}).filter(([key, value]) => key !== 'name').length > 0 && (
									<HeadlineArtistDetails
										expandable
										headlineArtist={event.headlineArtist}
									/>
								)}
							</Box>
						) : (
							<Oblique>No artists have been added to this event</Oblique>
						)}
					</Section>


					<Section
						hideDescriptions
						id='ticketing'
						name='Ticketing & Entry'
					>
						<Table>
							<TableBody>
								<TableRow>
									<Th>Age Restriction</Th>
									<TableCell>{ageRestrictions.find(x => x.slug === event.ageRestriction)?.name || event.ageRestriction}</TableCell>
								</TableRow>
								<TableRow>
									<Th>Ticket Price</Th>
									<TableCell>{event.prices?.ticket && formatPrice(event.prices.ticket)}</TableCell>
								</TableRow>
								<TableRow>
									<Th>Door Price</Th>
									<TableCell>{event.prices?.door && formatPrice(event.prices.door)}</TableCell>
								</TableRow>
								<TableRow>
									<Th>Ticket URL</Th>
									<TableCell>
										{event.ticketUrl ? (
											<MuiLink
												href={event.ticketUrl}
												target='_blank'
												rel='noreferrer'
												sx={{
													textDecoration: 'none'
												}}
											>
												{event.ticketUrl}
											</MuiLink>
										) : (
											<Oblique>
												No Ticket URL
											</Oblique>
										)}
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</Section>


					<Section
						hideDescriptions
						id='description'
						name='Description (bio etc)'
					>
						{event.description ? (
							<Typography
								variant='body2'
								sx={{
									wordBreak: 'break-all'
								}}
							>
								{event.description}
							</Typography>
						) : (
							<Oblique>No description</Oblique>
						)}
					</Section>


					<Section
						hideDescriptions
						id='eventImage'
						name='Image'
					>
						{event.image ? (
							<Image
								src={imageUrl.href}
								width='100%'
								crossOrigin='true'
							/>
						) : (
							<Oblique>No image</Oblique>
						)}
					</Section>


					<Section
						hideDescriptions
						id='eventVideo'
						name='Video'
					>
						{event.youtubeId ? (
							<YoutubeVideoEmbed videoId={event.youtubeId} />
						) : (
							<Oblique>No video</Oblique>
						)}
					</Section>
				</>)}


				<CheckPermission permission='viewEventExternalContact'>
					<Section
						hideDescriptions
						id='externalContact'
						name='External Contact (Promoter / agent / hire / etc)'
					>
						<Table>
							<TableBody>
								<TableRow>
									<Th>Promoter</Th>
									<TableCell>
										{event.externalContact?.promoter ?? <Oblique>n/a</Oblique>}
									</TableCell>
								</TableRow>
								<TableRow>
									<Th>Name</Th>
									<TableCell>
										{event.externalContact?.name ?? <Oblique>n/a</Oblique>}
									</TableCell>
								</TableRow>
								<TableRow>
									<Th>E-mail(s)</Th>
									<TableCell>
										{event.externalContact?.email ?? <Oblique>n/a</Oblique>}
									</TableCell>
								</TableRow>
								<TableRow>
								</TableRow>
							</TableBody>
						</Table>
					</Section>
				</CheckPermission>

				{event.type !== 'private' && (<>
					<CheckPermission permission='viewDetailedSales'>
						<Section
							hideDescriptions
							id='sales'
							name='Sales'
						>
							<TicketSalesChart event={event} />
						</Section>
					</CheckPermission>
				</>)}


				<CheckPermission permission='viewEventUpdateHistory'>
					<CollapsibleSection
						id='changes'
						name='Changes'
						sx={{gap: 0}}
					>
						{event.updates && Object.entries(event.updates).reverse().map(([
							id,
							{
								user,
								updates,
								datetime,
							}
						]) => {
							return (
								<Accordion key={id} sx={{width: 'auto'}}>
									<AccordionSummary
										expandIcon={<KeyboardArrowDown />}
									>
										<Typography
											variant='subtitle2'
											sx={{
												width: '33%',
												flexShrink: 0
											}}
										>
											{formatDateTime(datetime)}
										</Typography>
										<Typography
											variant='subtitle2'
											sx={{
												color: 'text.secondary'
											}}
										>
											{user.name}
										</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<Table>
											<TableBody>
												{updates && Object.entries(updates || {})
													.filter(([key, value]) =>
														key !== 'google'
														&& key !== 'links'
													).map(([key, value]) => (
														<UpdateRow
															key={`${id}-${key}`}
															field={key}
															value={value}
														/>
													))
												}
											</TableBody>
										</Table>
									</AccordionDetails>
								</Accordion>
							)
						})}
					</CollapsibleSection>
				</CheckPermission>


				<Section
					id='links'
					name='Links'
				>
					<EventExternalLinks event={event} />
				</Section>


				<CheckPermission permission='manageEvents'>
					<Box>
						<LinkButton
							variant='contained'
							startIcon={<Edit />}
							href={`/events/${event.uid}/edit`}
							disabled={eventHasPassed}
						>
							Edit this event
						</LinkButton>
					</Box>
				</CheckPermission>

			</Box>
		</Box>
	</>)
}


export default () => {
	const params = useParams()
	const loading = useSelector(state => state.loading.loading)
	const {
		events,
		loading: eventsDataLoading
	} = useSelector(state => state.events)

	const isLoading = useMemo(() => loading, [loading])
	const isEventsDataLoading = useMemo(() => eventsDataLoading, [eventsDataLoading])

	const [fetchedEvent, setFetchedEvent] = useState(null)
	const [fetchedEventLoading, setFetchedEventLoading] = useState(false)

	useEffect(() => {
		const fetchEvent = async () => {
			setFetchedEventLoading(true)
			try {
				const token = await getAuth().currentUser.getIdToken(true)
				const response = await api({
					url: `events/${params.uid}`,
					headers: {
						token
					}
				})

				console.log('response', response)
				setFetchedEvent(response.data)
			} catch (error) {
				console.error(error)
			} finally {
				setFetchedEventLoading(false)
			}
		}

		if (!isLoading && !isEventsDataLoading) {
			const futureEvent = events.find(event => event.uid === params.uid)
			if (!futureEvent) {
				
				fetchEvent()
			}
		}
	}, [])

	// if (isLoading || isEventsDataLoading || fetchedEventLoading) {
	// 	return <Loading />
	// }

	const futureEvent = events.find(event => event.uid === params.uid)
	if (futureEvent) {
		return <ViewEvent event={futureEvent} />
	}

	if (fetchedEvent) {
		return <ViewEvent event={fetchedEvent} />
	}


	if (!isLoading && isEventsDataLoading && fetchedEventLoading) {
		return (
			<Error error={{
				message: 'Event Not Found',
				data: `Couldn't find event with ID ${params.uid}`
			}} />
		)
	} else {
		return <Loading />
	}

	
}
