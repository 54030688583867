import {useSelector} from 'react-redux'
import {Route, Switch} from 'wouter'
import {usePaths} from 'data/paths'
import NotFound from 'pages/404'


const ProtectedRoute = ({
	children,
	path,
	permission = [],
	requiresAuthOnly
}) => {
	const {
		verified,
		verifiedUser
	} = useSelector(state => state.auth)

	if (!verified) {
		return <NotFound />
	}

	return (requiresAuthOnly || permission.some(x => verifiedUser.permissions.includes(x))) ? (
		<Route path={path}>
			{children}
		</Route>
	) : (
		<NotFound />
	)
}


export default () => {
	const paths = usePaths()

	return (
		<Switch>
			{paths.map(path => (path.requiresAuthOnly || path.permissions) ? (
				<ProtectedRoute
					key={path.path}
					path={path.path}
					permission={path.permissions}
					requiresAuthOnly={path.requiresAuthOnly}
				>
					{path.element}
				</ProtectedRoute>
			) : (
				<Route
					key={path.path}
					path={path.path}
				>
					{path.element}
				</Route>
			))}
			<Route>
				<NotFound />
			</Route>
		</Switch>
	)
}

