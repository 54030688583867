import {Box, Fade} from '@mui/material'

/**
 * @param {string} videoId the id of the video to embed
 */
export default ({
	videoId,
	...props
}) => (
	<Fade in timeout={1000}>
		<Box sx={{
			position: 'relative',
			width: '100%',
			paddingBottom: '56.25%'
		}} {...props}>
			<iframe
				src={`https://www.youtube.com/embed/${videoId}?modestbranding=1;rel=0`}
				title='YouTube video player'
				frameBorder='0'
				allow='clipboard-write; encrypted-media; web-share;'
				allowFullScreen
				style={{
					position: 'absolute',
					top: 0,
					left: 0,
					width: '100%',
					height: '100%',
					border: 0
				}}
			/>
		</Box>
	</Fade>
)