import {Image as MuiImage} from 'mui-image'

import {CircularProgress} from '@mui/material'


/**
 * https://github.com/benmneb/mui-image
 */

/**
 * @param {Object} props
 * @param {string} alt image alt tag value
 * @param {string} bgColor the color the image transitions in from
 * @param {string} className CSS class for the image
 * @param {string / number} distance any valid CSS length value (for the shift)
 * @param {number} duration sets the CSS transition-duration in milliseconds
 * @param {string} easing sets the CSS transition-timing-function
 * @param {boolean / node} errorIcon display default error icon, or your own
 * @param {string} fit any valid CSS object-fit value
 * @param {number / string} height any valid CSS height value
 * @param {string} iconWrapperClassName CSS class for the icon wrapper div
 * @param {object} iconWrapperStyle inline styles for the icon wrapper div
 * @param {string} position any valid CSS position value
 * @param {boolean / string} shift either "left", "right", "top", "bottom", null, or false
 * @param {number} shiftDuration duration of shift in milliseconds
 * @param {boolean / node} showLoading display default loading spinner, or your own
 * @param {string} src  image src tag... required
 * @param {object} style inline styles for the image
 * @param {number / string} width any valid CSS width value
 * @param {string} wrapperClassName CSS class for the root wrapper div
 * @param {object} wrapperStyle inline styles for the root wrapper div
 */
export default ({
	duration = 1000,
	showLoading = <CircularProgress />,
	errorIcon = true,
	...props
}) => (
	<MuiImage
		duration={duration}
		showLoading={showLoading}
		errorIcon={errorIcon}
		{...props}
	/>
)