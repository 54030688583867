import {useState, useEffect} from 'react'
import {useTheme} from '@mui/material/styles'
import {ticketOutlets} from 'data/ticketOutlets'
import {useSelector} from 'react-redux'
import CollapsibleCard from 'components/CollapsibleCard'
import TicketSalesChart from 'components/TicketSalesChart'

import {
	Box,
	CardContent,
	Fade,
	FormControl,
	InputLabel,
	LinearProgress,
	MenuItem,
	Select,
	Skeleton,
	Stack,
	Typography
} from '@mui/material'


const AllTicketSalesChart = () => {
	const {events} = useSelector(state => state.events)
	const theme = useTheme()

	const totalSales = events.reduce(
		(accumulator, {sales}) => {
			Object.entries(sales || {})
				.filter(([outlet, amount]) =>
					outlet !== 'guestlist'
				 && outlet !== 'door'
				 && amount > 0)
				.forEach(([outlet, amount]) => {
					accumulator[outlet] = (accumulator[outlet] || 0) + Number(amount)
				})
			return accumulator
		}, Object.fromEntries(ticketOutlets.map(({slug}) => [slug, 0]))
	)

	totalSales.total = Object.entries(totalSales).reduce(
		(accumulator, current) => {
			accumulator = accumulator + current[1]
			return accumulator
		}, 0
	)

	
	const filteredTicketOutlets = ticketOutlets.filter(({slug}) => slug !== 'guestlist' && slug !== 'door')

	return (<>
		<Stack spacing={1.5}>
			{filteredTicketOutlets.map(({name, slug}, i) => (
				<Fade
					in
					timeout={i * (3000/filteredTicketOutlets.length)}
					key={slug}
				>
					<Box key={slug}>
						<Typography variant='subtitle2'>
							{name || slug}
						</Typography>
						<Box sx={{display: 'flex', alignItems: 'center'}}>
							<Box sx={{width: '100%', position: 'relative'}}>
								<LinearProgress
									variant='determinate'
									color={theme.palette[slug] ? slug : 'primary'}
									value={totalSales[slug] / totalSales.total * 100}
									sx={{
										height: 18,
										border: `1px solid ${theme.palette.divider}`
									}}
								/>
								<Box sx={{
									top: 0,
									bottom: 0,
									right: 0,
									position: 'absolute',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center'
								}}>
									<Typography
										variant='body2'
										align='right'
										sx={{
											mr: .5,
											color: theme.palette[slug]?.contrastText,
											fontSize: '.75rem'
										}}
									>
										{totalSales[slug]}
									</Typography>
								</Box>
							</Box>
						</Box>
					</Box>
				</Fade>
			))}
		</Stack>
		<Box sx={{
			mt: 2,
			display: 'flex',
			justifyContent: 'space-between'
		}}>
			<Typography variant='subtitle2'>
				Total Sales
			</Typography>
			<Typography
				variant='subtitle2'
				align='right'
				sx={{mr: .5}}
			>
				{totalSales.total}
			</Typography>
		</Box>
	</>)
}


const renderLabelDate = dt => {
	const date = new Date(dt)
	return date.toLocaleDateString('en-gb')
}

export default ({
	defaultEvent = ''
}) => {
	const {events, loading} = useSelector(state => state.events)
	const [selectedEvent, setSelectedEvent] = useState(defaultEvent)
	const handleChange = e => setSelectedEvent(e.target.value)
	const selectLabel = events.length > 0 ? 'Event' : 'No events'

	useEffect(() => setSelectedEvent(defaultEvent), [defaultEvent])

	return (
		<CollapsibleCard
			variant='outlined'
			title='Ticket Sales'
			id='ticketSales'
		>
			<CardContent>
				<FormControl fullWidth size='small'>
					<InputLabel id='event-selector-label'>{selectLabel}</InputLabel>
					<Select
						labelId='event-selector-label'
						id='event-selector'
						value={selectedEvent}
						label={selectLabel}
						disabled={events.length === 0}
						onChange={handleChange}
					>
						{[
							<MenuItem
								value='all'
								key='all'
							>
								All
							</MenuItem>,
							(events.length > 0) && events.map(({uid, sales, start, name}) => (
								<MenuItem
									value={uid}
									key={uid}
								>
									({renderLabelDate(start)}) {name}
								</MenuItem>
							))
						]}
					</Select>
				</FormControl>

				<Box sx={{mt: 2}}>
					{loading ? (
						<Skeleton />
					) : (
						selectedEvent === 'all' ? (
							<AllTicketSalesChart />
						) : (
							<TicketSalesChart event={events.find(({uid}) => uid === selectedEvent)} />
						)
					)}
				</Box>
			</CardContent>
		</CollapsibleCard>
	)
}