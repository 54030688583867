import {
	Box
} from '@mui/material'

export default () => {
	return (
		<Box>
			<h2>todo</h2>
			<ul>
				<li>
					font switcher ('normal' vs 'dyslexic' ie. lexend)
				</li>
				<li>
					colour schemes
				</li>
			</ul>
		</Box>
	)
}