import {Box} from '@mui/material'

export default ({
	children,
	sx,
	...props
}) => (
	<Box
		sx={{
			display: 'flex',
			gap: 1,
			alignItems: 'flex-start',
			...sx
		}}
		{...props}
	>
		{children}
	</Box>
)