import {useDispatch} from 'react-redux'
import {auth, signOut as firebaseSignOut} from 'providers/firebase'
import {clearUser, setError} from 'providers/slices/auth'

export default () => {
	const dispatch = useDispatch()

	return async () => {
		try {
			await firebaseSignOut(auth)
			dispatch(clearUser())
		} catch (error) {
			console.error(error)
			dispatch(setError(error.message ?? error))
		}	
	}
}