import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'

dayjs.extend(advancedFormat)

export const now = new Date()

/**
 * Formats a date (Monday, 1st January) using dayjs
 * @param {(object|number)} value the date
 */
export const formatDate = value => {
	const dateObj = dayjs(value)
	return dateObj.format(dateObj.get('year') !== now.getFullYear() ? 'dddd, Do MMMM YYYY' : 'dddd, Do MMMM')
}


/**
 * Formats a date (Mon, 1st Jan) using dayjs
 * @param {(object|number)} value the date
 */
export const formatDateShort = value => {
	const dateObj = dayjs(value)
	return dateObj.format(dateObj.get('year') !== now.getFullYear() ? 'ddd, Do MMM [\']YY' : 'ddd, Do MMM')
}


/**
 * Formats a date (Mon, 1st Jan) using dayjs
 * @param {(object|number)} value the date
 */
export const formatDateVeryShort = value => {
	const dateObj = dayjs(value)
	return dateObj.format(dateObj.get('year') !== now.getFullYear() ? 'dd DD/MM/YY' : 'dd DD/MM')
}


/**
 * Formats a date (01/01/23) using dayjs
 * @param {(object|number)} value the date
 */
export const formatDateXShort = value => {
	const dateObj = dayjs(value)
	return dateObj.format('DD/MM/YY')
}


/**
 * Formats a date as a time using dayjs
 * @param {(object|number)} value the date
 */
export const formatTime = value => {
	const dateObj = dayjs(value)
	return dateObj.format('HH:mm')
}


/**
 * Formats a date as a start - end time using dayjs
 * @param {(object|number)} start the date
 * @param {(object|number)=} end the date
 */
export const formatStartEndTime = (start, end) => {
	const startObj = dayjs(start)
	const endObj = end && dayjs(end)
	return `${startObj.format('HH:mm')}${end ? ` - ${endObj.format('HH:mm')}` : ''}`
}


/**
 * Formats a date and a time using dayjs
 * @param {(object|number)} value the date
 */
export const formatDateTime = value => {
	const dateObj = dayjs(value)
	return dateObj.format(dateObj.get('year') !== now.getFullYear() ? 'dddd, Do MMMM [\']YY [at] HH:mm' : 'dddd, Do MMMM [at] HH:mm')
}