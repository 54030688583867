import axios from 'axios'


export const apiUrl = process.env.NODE_ENV === 'production' ? 'https://api.theliverooms.com/' : 'http://localhost:3001'


export const api = axios.create({
	baseURL: apiUrl,
	timeout: 10000
})


export const log = ({
	token,
	message,
	level,
	...data
}) => token && api({
	url: '/log',
	method: 'post',
	headers: {
		'Content-Type': 'application/json',
		token
	},
	data: JSON.stringify({
		message,
		level,
		data
	})
})


export const requestAccess = ({
	token
}) => token && api({
	url: '/user/requestAccess',
	method: 'post',
	headers: {
		token
	}
})


export const verifyToken = token => api({
	method: 'post',
	url: '/user/auth',
	headers: {
		token
	}
})