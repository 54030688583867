export const ticketOutlets = [{
	slug: 'promoter',
	name: 'Promoter\'s Sales',
	colour: '#73bc73'
},{
	slug: 'seetickets',
	name: 'See Tickets',
	colour: '#007a7b'
},{
	slug: 'ticketweb',
	name: 'Ticketweb',
	colour: '#65357a'
},{
	slug: 'gigantic',
	name: 'Gigantic',
	colour: '#b43d0b'
},{
	slug: 'eventbrite',
	name: 'Eventbrite',
	colour: '#d1410c'
},{
	slug: 'tlr',
	name: 'The Live Rooms',
	colour: '#1c58bf'
},{
	slug: 'other',
	name: 'Other / Misc',
	colour: '#808080'
},{
	slug: 'grey-n-pink',
	name: 'Grey n Pink',
	colour: '#cdb0a6'
},{
	slug: 'up-north-records',
	name: 'Up North Records',
	colour: '#e20512'
},{
	slug: 'supports',
	name: 'Supports',
	colour: '#ffd54f'
},{
	slug: 'door',
	name: 'Door',
	colour: '#1c58bf'
},{
	slug: 'guestlist',
	name: 'Guestlist',
	colour: '#ffd700'
},{
	slug: 'presales',
	name: 'Presales (Legacy)',
	colour: '#1c58bf'
}]
