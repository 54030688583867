export const permissionsObject = {
	admin: {
		slug: 'admin',
		label: 'Admin',
		description: 'Administrative access',
		type: 'super',
		module: 'base'
	},
	assignChecklists: {
		slug: 'assignChecklists',
		label: 'Assign Checklists',
		description: 'Allows the user to assign a checklist to be completed by personnel',
		type: 'manage',
		module: 'checklists'
	},
	fillInChecklists: {
		slug: 'fillInChecklists',
		label: 'Fill In Checklists',
		description: 'Allows the user to fill in checklists',
		type: 'manage',
		module: 'checklists'
	},
	manageAccessibilityRequests: {
		slug: 'manageAccessibilityRequests',
		label: 'Manage Accessibility Requests',
		description: 'Allows the user to add, remove and update accessibility requests from customers',
		type: 'manage',
		module: 'events'
	},
	manageChecklists: {
		slug: 'manageChecklists',
		label: 'Manage Checklists',
		description: 'Allows the user to create new checklists, and update or delete existing ones',
		type: 'manage',
		module: 'checklists'
	},
	manageEvents: {
		slug: 'manageEvents',
		label: 'Manage Events',
		description: 'Allows the user to create and update events',
		type: 'manage',
		module: 'events'
	},
	managePersonnel: {
		slug: 'managePersonnel',
		label: 'Manage Personnel',
		description: 'Allows the user to create personnel and update their details',
		type: 'manage',
		module: 'staffing'
	},
	manageRoles: {
		slug: 'manageRoles',
		label: 'Manage Roles',
		description: 'Allows the user to create and update roles, that can then be assigned to personnel',
		type: 'manage',
		module: 'staffing'
	},
	manageRota: {
		slug: 'manageRota',
		label: 'Manage Rota',
		description: 'Allows the user to add and remove personnel from the rota for events',
		type: 'manage',
		module: 'staffing'
	},
	manageUsers: {
		slug: 'manageUsers',
		label: 'Manage Users',
		description: 'Allows the user to create, update and delete users of the app',
		type: 'manage',
		module: 'base'
	},
	manageVenue: {
		slug: 'manageVenue',
		label: 'Manage Venue Configuration',
		description: 'Allows the user to configure and edit venue-wide settings',
		type: 'super',
		module: 'base'
	},
	modifyChecklistSubmissions: {
		slug: 'manageChecklistSubmissions',
		label: 'Manage Checklist Submissions',
		description: 'Allows the user to edit previously submitted checklists',
		type: 'manage',
		module: 'checklists'
	},
	testing: {
		slug: 'testing',
		label: 'Testing',
		description: 'Allows the user to see testing areas',
		type: 'super',
		module: 'base'
	},
	updateSales: {
		slug: 'updateSales',
		label: 'Update Sales',
		description: 'Allows the user to update event/outlet sales',
		type: 'manage',
		module: 'events'
	},
	viewAccessibilityRequests: {
		slug: 'viewAccessibilityRequests',
		label: 'View Accessibility Requests',
		description: 'Allows the user to see details submitted as part of accessibility requests',
		type: 'sensitive',
		module: 'events'
	},
	viewChecklistSubmissions: {
		slug: 'viewChecklistSubmissions',
		label: 'View Checklist Submissions',
		description: 'Allows the user to view historical checklist submissions',
		type: 'view',
		module: 'checklists'
	},
	viewDetailedSales: {
		slug: 'viewDetailedSales',
		label: 'View Detailed Sales',
		description: 'Allows the user to see details regarding event sales',
		type: 'view',
		module: 'events'
	},
	viewEvents: {
		slug: 'viewEvents',
		label: 'View Events',
		description: 'Allows the user to view events',
		type: 'view',
		module: 'events'
	},
	viewEventExternalContact: {
		slug: 'viewEventExternalContact',
		label: 'View Event External Contact',
		description: 'Allows the user to view the details in the External Contact section of events',
		type: 'sensitive',
		module: 'events'
	},
	viewEventUpdateHistory: {
		slug: 'viewEventUpdateHistory',
		label: 'View Event Update History',
		description: 'Allows the user to see previous versions of events',
		type: 'sensitive',
		module: 'events'
	},
	viewPersonnel: {
		slug: 'viewPersonnel',
		label: 'View Personnel',
		description: 'Allows the user to view personnel',
		type: 'view',
		module: 'staffing'
	},
	viewPersonnelSensitive: {
		slug: 'viewPersonnelSensitive',
		label: 'View Personnel (Sensitive)',
		description: 'Allows the user to view sensitive details regarding personnel',
		type: 'sensitive',
		module: 'staffing'
	},
	viewRoles: {
		slug: 'viewRoles',
		label: 'View Roles',
		description: 'Allows the user to view the roles that may be assigned to personnel',
		type: 'view',
		module: 'staffing'
	},
	viewRota: {
		slug: 'viewRota',
		label: 'View Rota',
		description: 'Allows the user to view the rota for events',
		type: 'view',
		module: 'staffing'
	},
	viewUsers: {
		slug: 'viewUsers',
		label: 'View Users',
		description: 'Allows the user to view the users of the app',
		type: 'view',
		module: 'base'
	},
	viewVenue: {
		slug: 'viewVenue',
		label: 'View Venue Configuration',
		description: 'Allows the user to view venue-wide configuration and settings',
		type: 'view',
		module: 'base'
	}
}


export const permissionsByModule = Object.values(
	Object.entries(permissionsObject).reduce((acc, [permission, data]) => {
		const {module, ...rest} = data
		if (!acc[module]) {
			acc[module] = {module, permissions: []}
		}
		acc[module].permissions.push({permission, ...rest})
		return acc
	}, {})
)


export const permissions = Object.values(permissionsObject)
export const permissionsSlugs = permissions.map(permission => permission.slug)
export default permissions

