import {useTheme} from '@mui/material/styles'
import {Box} from '@mui/material'

/**
 * @param heightOffset - can be string, number, or true for default value
 */
export default ({
	children,
	heightOffset,
	sx,
	...props
}) => {
	const theme = useTheme()

	let height
	switch (typeof heightOffset) {
		case 'boolean': {
			height = heightOffset ? `calc(100vh - ${theme.spacing(16)})` : '100dvh'
			break
		}
		case 'string': {
			height = isNaN(heightOffset) ? heightOffset : `${heightOffset}px`
			break
		}
		case 'number': {
			height = `${heightOffset}px`
			break
		}
		default: {
			height = '100vh'
			break
		}
	}

	return (
		<Box sx={{
			padding: 2,
			height,
			display: 'flex',
			alignContent: 'space-around',
			justifyContent: 'center',
			alignItems: 'center',
			flexDirection: 'column',
			gap: 4,
			...sx
		}} {...props}>
			{children}
		</Box>
	)
}