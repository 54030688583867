import {useEffect} from 'react'
import {db} from 'providers/firebase'
import {ref, onValue} from 'firebase/database'
import {useDispatch, useSelector} from 'react-redux'
import {
	setUserPreferences,
	clearUserPreferences,
	setError,
	setLoading
} from 'providers/slices/userPreferences'

export default () => {
	const dispatch = useDispatch()
	const {loading} = useSelector(state => state.loading)
	const {
		loading: authLoading,
		verifiedUser
	 } = useSelector(state => state.auth)
	 const {loading: userPreferencesLoading} = useSelector(state => state.userPreferences)

	useEffect(() => {
		if (!userPreferencesLoading) {dispatch(setLoading(true))}
		if (verifiedUser?.uid) {
			const userPreferencesRef = ref(db, `/userPreferences/${verifiedUser.uid}`)
			onValue(userPreferencesRef, snapshot => {
				console.log('[userPreferences]', snapshot.val())
				dispatch(setUserPreferences(snapshot.val()))
			}, error => {
				setError(error)
			})
		} else {
			dispatch(clearUserPreferences())
		}
	}, [authLoading, loading, verifiedUser])
}
