import {useController} from 'react-hook-form'
import {
	TextField,
	MenuItem
} from '@mui/material'
import {rooms} from 'data/eventOptions'

export default ({
	name,
	control
}) => {
	const {field} = useController({
		name,
		control
	})
	
	return (
		<TextField
			{...field}
			fullWidth
			required
			select
			variant='outlined'
			size='small'
			label='Room'
		>
			{rooms.map(({name, slug}) => (
				<MenuItem value={name} key={slug}>
					{name}
				</MenuItem>
			))}
		</TextField>
	)
}