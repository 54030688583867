import {useState, useEffect, forwardRef} from 'react'
import axios from 'axios'

import {useTheme} from '@mui/material/styles'

import {
	Box,
	
} from '@mui/material'



export default function DashboardPage() {
	const theme = useTheme()
	
	return (
		<Box sx={{
			height: '100%',
			overflow: 'hidden'
		}}>
			testing
		</Box>
	)
}