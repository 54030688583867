export default {
	base: {
		slug: 'base',
		label: 'Base'
	},
	checklists: {
		slug: 'checklists',
		label: 'Checklists'
	},
	events: {
		slug: 'events',
		label: 'Events'
	},
	staffing: {
		slug: 'staffing',
		label: 'Staffing'
	}
}