import {Tooltip as MuiTooltip} from '@mui/material'

export default ({
	children,
	sx,
	...props
}) => (
	<MuiTooltip
		arrow
		placement='left'
		{...props}
	>
		{children}
	</MuiTooltip>
)
