import {useState} from 'react'
import {useController} from 'react-hook-form'
import {DateTimePicker} from '@mui/x-date-pickers/DateTimePicker'
import dayjs from 'dayjs'

export default ({
	control,
	error,
	name
}) => {
	const {
		field: {
			onBlur,
			onChange,
			...field
		},
		formState: {
			defaultValues
		}
	} = useController({
		control,
		name,
		rules: {
			required: 'Start date / time is required',
			validate: {
				disablePast: value => !dayjs(value).isBefore(dayjs()) || 'Start date / time cannot be in the past.'
			}
		}
	})

	const [focused, setFocused] = useState(false)

	const handleOnChange = value => {
		onChange((defaultValues[name] || focused) ? value : dayjs(value).set('hour', 19))
		setFocused(true)
	}

	return (
		<DateTimePicker
			{...field}
			onChange={handleOnChange}
			variant='outlined'
			label='Start Date / Time'
			required
			disablePast
			disableHighlightToday
			timeSteps={{
				minutes: 15
			}}
			format="dddd[,] Do MMMM YYYY [at] HH[:]mm"
			slotProps={{
				textField: {
					onBlur,
					required: true,
					size: 'small',
					error: !!error,
					helperText: error?.message,
					fullWidth: true,
					mask: '__/__/____ __:__'
				}
			}}
		/>
	)
}