import {useCallback, useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import Link from 'components/Link'

import {db, ref, update} from 'providers/firebase'
import {setOpen} from 'providers/slices/sidebar'

import {ageRestrictions, eventStatuses, rooms} from 'data/eventOptions'

import {blank, renderBlank} from 'functions/blank.js'
import {formatDate, formatDateVeryShort, formatStartEndTime} from 'functions/formatDates'
import {openExternalLink} from 'functions/openExternalLink'
import formatPrice from 'functions/formatPrice'
import sumSales from 'functions/sumSales'
import usePastEvents from 'functions/usePastEvents'

import {
	Box,
	Tooltip,
	useMediaQuery,
	useTheme
} from '@mui/material'

import {
	DataGrid,
	GridActionsCellItem,
	GridFooterContainer,
	GridPagination,
	GridToolbar,
	GridToolbarQuickFilter
} from '@mui/x-data-grid'

import {Icon} from '@iconify/react'


const renderStatusIcon = ({value}) => {
	const status = eventStatuses.find(x => x.slug === value)
	return status ? (
		<Tooltip
			disableInteractive
			arrow
			placement='right'
			title={status.name}
		>
			<status.Icon
				sx={{
					fontSize: '1rem',
					verticalAlign: 'middle'
				}}
			/>
		</Tooltip>
	) : blank
}


const renderName = params => (
	<Link
		style={{
			color: 'inherit',
			textDecoration: 'none'
		}}
		to={`/events/${params.row.id}`}
	>
		{params.row.name}
	</Link>
)


const Footer = () => {
	const theme = useTheme()
	const showCompact = useMediaQuery(theme.breakpoints.down('md'))
	return (
		<GridFooterContainer>
			<GridToolbarQuickFilter sx={{
				maxWidth: '40%',
				mx: showCompact ? 1 : 2,
				'& input': {
					fontSize: '.875rem'
				}
			}} />
			<GridPagination />
		</GridFooterContainer>
	)
}


const size = (minWidth, maxWidth) => ({minWidth, maxWidth: maxWidth || minWidth})


export default () => {
	usePastEvents()
	const theme = useTheme()
	const dispatch = useDispatch()

	const {pastEvents, loading} = useSelector(state => state.pastEvents)

	const userData = useSelector(state => state.userData.userData)
	const preferences = userData?.preferences ?? {}

	const showCompact = useMediaQuery(theme.breakpoints.down('md'))
	const lessThanLg = useMediaQuery(theme.breakpoints.down('lg'))

	const [columnVisibilityModel, setColumnVisibilityModel] = useState(preferences.viewPastEvents || {
		room: false
	})
	const onColumnVisibilityModelChange = (model, details) => {
		setColumnVisibilityModel(prev => ({
			...prev,
			...model
		}))

		update(ref(db, `/users/${userData.uid}/preferences/viewPastEvents`), model).catch(console.error)
	}


	useEffect(() => {
		if (lessThanLg) {
			dispatch(setOpen(false))
		}
	}, [lessThanLg])


	const getActions = params => {
		const {
			links: {
				boxOfficeSheet,
				calendar,
				drive
			}
		} = params.row
		
		const items = [
			<GridActionsCellItem
				showInMenu
				dense
				icon={<Icon icon='logos:google-calendar' height='18' />}
				label='Calendar event'
				disabled={!calendar}
				onClick={() => openExternalLink(calendar)}
			/>,
			<GridActionsCellItem
				showInMenu
				dense
				icon={<Icon icon='logos:google-drive' height='18' />}
				label='Drive folder'
				disabled={!drive}
				onClick={() => openExternalLink(drive)}
			/>,
			<GridActionsCellItem
				showInMenu
				dense
				icon={<Icon icon='mdi:file-document' height='18' color='#2684fc' />}
				label='Box Office sheet'
				disabled={!boxOfficeSheet}
				onClick={() => openExternalLink(boxOfficeSheet)}
			/>
		]
	
		return items
	}
	
	
	const columns = useCallback(() => [{
		cellClassName: 'statusCell',
		field: 'status',
		headerClassName: 'statusHeader',
		headerName: 'Status',
		renderCell: renderStatusIcon,
		renderHeader: renderBlank,
		sortable: false,
		type: 'singleSelect',
		valueOptions: eventStatuses.map(status => ({
			label: status.name,
			value: status.slug
		})),
		...size(12, 36)
	},{
		field: 'date',
		flex: 1,
		headerName: 'Date',
		...showCompact ? size(96, 192) : size(192, 256),
		type: 'date',
		valueFormatter: ({value}) => showCompact ? formatDateVeryShort(value) : formatDate(value),
		valueGetter: ({value}) => value && new Date(value)
	},{
		field: 'name',
		flex: 1.5,
		headerName: 'Name',
		renderCell: renderName,
		...size(256, 512),
	},{
		field: 'room',
		headerName: 'Room',
		flex: 1,
		...size(72),
		valueGetter: ({value}) => rooms.find(room => room.slug === value)?.name || value
	},{
		headerName: 'Time',
		field: 'time',
		flex: 1,
		...size(104),
		type: 'dateTime',
		valueFormatter: ({value, id}) => {
			const event = pastEvents.find(({uid}) => uid === id)
			return formatStartEndTime(value, event.end)
		},
		valueGetter: ({value}) => value && new Date(value)
	},{
		headerName: 'Age Restriction',
		field: 'age',
		flex: 1,
		...size(50, 96),
		valueGetter: ({value}) => ageRestrictions.find(x => value === x.slug)?.name ?? value
	},{
		field: 'priceAdv',
		flex: 1,
		headerName: 'Price (adv)',
		...size(72, 192),
		type: 'number',
		valueFormatter: ({value}) => typeof value !== 'undefined' ? formatPrice(value) : 'Unknown',
	},{
		field: 'priceDoor',
		flex: 1,
		headerName: 'Price (door)',
		...size(72, 192),
		type: 'number',
		valueFormatter: ({value}) => typeof value !== 'undefined' ? formatPrice(value) : 'Unknown'
	},{
		field: 'sales',
		flex: 1,
		headerName: 'Sales',
		...size(50, 128),
		type: 'number'
	},{
		align: 'right',
		field: 'links',
		getActions,
		headerName: 'Links',
		renderHeader: renderBlank,
		// ...size(12, 40),
		type: 'actions'		
	}])

	const rows = useMemo(() => (
		pastEvents.map(event => ({
			id: event.uid,
			date: event.start,
			links: {
				boxOfficeSheet: event.google?.boxOfficeSheet?.url,
				calendar: event.links?.calendar,
				drive: event.links?.drive,
			},
			age: event.ageRestriction,
			priceAdv: event.prices?.ticket,
			priceDoor: event.prices?.door,
			name: event.name,
			room: event.room,
			sales: sumSales(event.sales),
			status: event.status,
			time: event.start
		}))
	), [pastEvents, loading])


	const initialState = {
		sorting: {
			sortModel: [{
				field: 'date',
				sort: 'desc'
			}]
		}
	}

	return (
		<Box sx={{
			height: `calc(100vh - ${theme.spacing(13)})`,
		}}>
			<Box
				id='events-datagrid'
				sx={{
					height: '100%',
					display: 'flex',
				}}
			>
				<DataGrid
					autoPageSize
					columns={columns()}
					columnVisibilityModel={columnVisibilityModel}
					onColumnVisibilityModelChange={onColumnVisibilityModelChange}
					density='compact'
					initialState={initialState}
					loading={loading}
					rows={rows}
					slots={{
						footer: Footer,
						toolbar: GridToolbar
					}}
				/>
			</Box>
		</Box>
	)
}