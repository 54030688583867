import {useController, useWatch} from 'react-hook-form'
import {TimePicker} from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import minMax from 'dayjs/plugin/minMax'

dayjs.extend(minMax)


export default ({
	name,
	control
}) => {
	const {field} = useController({
		name,
		control
	})

	const start = useWatch({
		control,
		name: 'start'
	})

	const end = useWatch({
		control,
		name: 'end'
	})

	const supportArtists = useWatch({
		control,
		name: 'supportArtists'
	})
	
	const maxTimes = [start]
	if (Array.isArray(supportArtists)) {
		supportArtists.filter(obj => obj.start instanceof dayjs).forEach(obj => maxTimes.push(obj.start))
		supportArtists.filter(obj => obj.end instanceof dayjs).forEach(obj => maxTimes.push(obj.end))
	}
	const minTime = maxTimes.filter(Boolean).length > 0 ? dayjs.max(maxTimes) : null

	return (
		<>
			<TimePicker
				{...field}
				label='Start'
				minutesStep={5}
				minTime={minTime}
				maxTime={end}
				disabled={!start}
				skipDisabled
				slotProps={{
					textField: {
						size: 'small'
					}
				}}
			/>
		</>
	)
}
