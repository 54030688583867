import {createSlice} from '@reduxjs/toolkit'

const initialState = {
	open: true,
	collapsed: {}
}

const sidebarSlice = createSlice({
	name: 'sidebar',
	initialState,
	reducers: {
		setOpen: (state, action) => {
			state.open = action.payload
		},
		collapsed: (state, action) => {
			state.collapsed[action.payload] = true
		},
		expanded: (state, action) => {
			state.collapsed[action.payload] = false
		}
	}
})


export const {
	setOpen,
	collapsed,
	expanded
} = sidebarSlice.actions

export default sidebarSlice.reducer