import {useController} from 'react-hook-form'
import {TextField} from '@mui/material'
import FormBox from 'components/events/FormBox'

export default ({
	name,
	error,
	control
}) => {
	const {field} = useController({
		name,
		control,
		rules: {
			maxLength: {
				value: 255,
				message: 'Max length: 255'
			}
		}
	})
	
	return (<>
		<FormBox>
			<TextField
				{...field}
				fullWidth
				size='small'
				variant='outlined'
				label='Name'
				error={!!error?.message}
				helperText={error?.message}
			/>
		</FormBox>
	</>)
}