import {useState} from 'react'
import {
	Box,
	Collapse,
	IconButton,
	Paper,
	Typography
 } from '@mui/material'
 import {ExpandLess, ExpandMore} from '@mui/icons-material'

export default ({
	children,
	name,
	sx,
	isExpanded = false,
	...props
}) => {
	const [expanded, setExpanded] = useState(isExpanded)
	const toggleExpanded = () => setExpanded(!expanded)

	return (
		<Paper
			variant='outlined'
			sx={{
				p: 1,
				pl: 2,
				display: 'flex',
				flexDirection: 'column',
				gap: 2,
				...sx
			}}
			{...props}
		>
			<Box>
				<Box sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center'
				}}>
					{name &&
						<Typography variant='h6' component='h2' sx={{verticalAlign: 'middle'}}>
							{name}
						</Typography>
					}
					<IconButton
						aria-expanded={expanded}
						onClick={toggleExpanded}
					>
						{expanded ? <ExpandLess /> : <ExpandMore />}
					</IconButton>
				</Box>
				<Collapse in={expanded}>
					<Box sx={{mt: 2}}>
						{children}
					</Box>
				</Collapse>
			</Box>
		</Paper>
	)
}
