import {createSlice} from '@reduxjs/toolkit'
import {auth, signOut as firebaseSignOut} from 'providers/firebase'

const initialState = {
	authenticated: false,
	error: null,
	loading: false,
	user: null,
	verified: false,
	verifying: false,
	verifiedUser: null
}


const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setUser: (state, action) => {
			state.user = action.payload
			state.authenticated = true
			state.verified = false
			state.error = null
		},
		setVerifiedUser: (state, action) => {
			state.verifiedUser = action.payload
			state.verified = true
			state.loading = false
			state.error = null
		},
		clearUser: (state, action) => {
			firebaseSignOut(auth)
			state.authenticated = false
			state.loading = false
			state.user = null
			state.verified = false
			state.verifying = false
			state.verifiedUser = null
		},
		setLoading: (state, action) => {
			state.loading = action.payload
		},
		setError: (state, action) => {
			state.error = action.payload
			state.loading = false
		},
		setVerifying: (state, action) => {
			state.verifying = action.payload
			state.loading = action.payload
		}
	}
})

export const {
	setUser,
	setVerifiedUser,
	clearUser,
	setLoading,
	setError,
	setVerifying
} = authSlice.actions

export default authSlice.reducer