import {createSlice} from '@reduxjs/toolkit'


const initialState = {
	error: null,
	loading: true,
	userPreferences: {}
}


const authSlice = createSlice({
	name: 'userPreferences',
	initialState,
	reducers: {
		setUserPreferences: (state, action) => {
			state.userPreferences = action.payload
			state.loading = false
			state.error = null
		},
		clearUserPreferences: (state, action) => {
			state.userPreferences = {}
			state.loading = false
			state.error = null
		},
		setLoading: (state, action) => {
			state.loading = action.payload
		},
		setError: (state, action) => {
			state.error = action.payload
			state.loading = false
		}
	}
})

export const {
	setUserPreferences,
	clearUserPreferences,
	setLoading,
	setError
} = authSlice.actions

export default authSlice.reducer