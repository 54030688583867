import {useController} from 'react-hook-form'
import {
	TextField,
	MenuItem
} from '@mui/material'
import {ageRestrictions} from 'data/eventOptions'

export default ({
	control,
	name
}) => {
	const {field} = useController({
		control,
		name
	})
	return (
		<TextField
			{...field}
			fullWidth
			required
			select
			variant='outlined'
			size='small'
			label='Age Restriction'
		>
			{ageRestrictions.map(age => (
				<MenuItem value={age.slug} key={age.slug}>
					{age.name}
				</MenuItem>
			))}
		</TextField>
	)
}