import {useEffect} from 'react'
import {db} from 'providers/firebase'
import {ref, onValue} from 'firebase/database'
import {useDispatch, useSelector} from 'react-redux'
import {
	clearEvents,
	setEvents,
	setError,
	setLoading
} from 'providers/slices/events'

export default () => {
	const dispatch = useDispatch()
	const {loading} = useSelector(state => state.loading)
	const {
		loading: authLoading,
		verifiedUser
	 } = useSelector(state => state.auth)
	const requiredPermissions = ['viewEvents', 'manageEvents']

	useEffect(() => {
		if (!authLoading && verifiedUser?.permissions && requiredPermissions.some(x => verifiedUser.permissions.includes(x))) {
			dispatch(setLoading(true))
			const eventsRef = ref(db, '/events')
			onValue(eventsRef, snapshot => {
				if (!loading) {dispatch(setLoading(true))}
				const now = new Date()
				const yesterday = now.setUTCHours(5,0,0,0)-86400000
				dispatch(
					setEvents(
						Object.values(snapshot.val() || {})
							.sort((a, b) => (a.start - b.start))
							.filter(event => parseInt(event.start) && (new Date(event.start).setUTCHours(0,0,0,0) >= yesterday))
						)
					)
			}, error => {
				setError(error)
			})
		} else {
			dispatch(clearEvents())
		}
	}, [verifiedUser?.permissions])
}