import {useEffect, useState} from 'react'
import {
	Card,
	CardActions,
	CardHeader,
	Collapse,
	IconButton,
	Skeleton
} from '@mui/material'
import {ExpandLess, ExpandMore} from '@mui/icons-material'
import {useSelector} from 'react-redux'
import {db} from 'providers/firebase'
import {ref, update} from 'firebase/database'

export default ({
	actions,
	children,
	collapsedSubtitle,
	headerProps,
	id,
	subtitle,
	sx,
	title = '',
	...props
}) => {
	const {user} = useSelector(state => state.auth)
	const {loading, userPreferences} = useSelector(state => state.userPreferences)
	const [expanded, setExpanded] = useState(userPreferences?.cards?.[id]?.expanded ?? false)

	const setExpandedHandler = expanded => {
		setExpanded(!!(expanded ?? userPreferences?.cards?.[id]?.expanded))
	}

	useEffect(() => {
		setExpandedHandler()
	}, [userPreferences])
	
	const toggleExpanded = () => {
		setExpandedHandler(!expanded)
		update(ref(db, `/userPreferences/${user.uid}/cards/${id}`), {
			expanded: !expanded
		})
		.catch(console.error)
	}


	return loading ? (
		<Skeleton height={150} />
	) : (
		<Card
			id={id}
			sx={{
				display: 'flex',
				flexDirection: 'column',
				...props.sx
			}}
			elevation={props.variant === 'outlined' ? undefined : 4}
			{...props}
		>
			<CardHeader
				title={title}
				subheader={subtitle || (!expanded ? collapsedSubtitle : undefined)}
				subheaderTypographyProps={{
					fontSize: '.825rem'
				}}
				action={
					<IconButton
						aria-expanded={expanded}
						onClick={toggleExpanded}
					>
						{expanded ? <ExpandLess /> : <ExpandMore />}
					</IconButton>
				}
				{...headerProps}
			/>
			
			<Collapse in={expanded}>
				{children}
				{actions && (
					<CardActions sx={{
						pt: 0,
						justifyContent: 'space-between',
						mt: 'auto'
					}}>
						{actions}
					</CardActions>
				)}
			</Collapse>

		</Card>
	)
}