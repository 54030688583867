import AppBar from 'components/layout/AppBar'
import Sidebar from 'components/layout/Sidebar'
import {
	Box,
	useMediaQuery,
	useTheme
} from '@mui/material'


export default ({
	children
}) => {
	const theme = useTheme()
	const smallViewport = useMediaQuery(theme.breakpoints.down('md'))
	const topMargin = theme.spacing(smallViewport ? 7 : 8)

	return (
		<Box
			id='layout'
			sx={{
				display: 'flex',
				background: theme.palette.dark
			}}
		>
			<AppBar />
			<Sidebar />
			<Box
				id='content'
				component='main'
				sx={{
					background: theme.palette.normal,
					flexGrow: 1,
					maxWidth: '100%',
					minHeight: `calc(100vh - (${theme.spacing(smallViewport ? 7 : 8)}))`,
					mt: topMargin,
					p: 2,//smallViewport ? 1 : 2,
					...!smallViewport && {
						borderTopLeftRadius: 12,
					},
				}}
			>
				{children}
			</Box>
		</Box>
	)
}