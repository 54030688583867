import {
	Link as MuiLink,
	ListItem,
	ListItemButton,
	ListItemIcon,
	Tooltip,
	useMediaQuery,
	useTheme
} from '@mui/material'

import ListItemLabel from 'components/events/ListItemLabel'


export default ({
	disabled,
	icon,
	name,
	target,
	sx,
	...props
}) => {
	const theme = useTheme()
	const smallViewport = useMediaQuery(theme.breakpoints.down('md'))

	const handleScroll = targetId => event => {
		event.preventDefault()
		const targetSection = document.getElementById(targetId.charAt(0) === '#' ? targetId.slice(1) : targetId)

		if (targetSection) {
			window.scrollTo({
				top: (targetSection.getBoundingClientRect().top + window.scrollY) - (smallViewport ? 72 : 80),
				left: 0,
				behavior: 'smooth'
			})
		}
	}


	const LinkButton = ({children}) => {
		return target.charAt(0) === '#' ? (
			<ListItemButton
				disabled={disabled}
				href={target}
				onClick={handleScroll(target)}
			>
				{children}
			</ListItemButton>
		) : (
			<ListItemButton
				component={MuiLink}
				disabled={disabled}
				to={target}
				target='_blank'
			>
				{children}
			</ListItemButton>
		)
	}


	const Item = props => {
		return (
			<ListItem
				disablePadding
				{...props}
			>
				<LinkButton
					href={target}
					onClick={handleScroll(target)}
				>
					{icon && (
						<ListItemIcon sx={{minWidth: '42px'}}>
							{icon}
						</ListItemIcon>
					)}
					<ListItemLabel
						inset={!icon}
						primary={name}
						sx={{...!icon && {paddingLeft: '42px'}}}
					/>
				</LinkButton>
			</ListItem>
		)
	}

	return smallViewport ? (
		<Tooltip
			arrow
			placement='right'
			title={name}
			{...props}
		>
			<span>
				<Item />
			</span>
		</Tooltip>
	) :(
		<Item />
	)
}