import {Tooltip} from '@mui/material'
import {eventStatuses, eventStatusesPrivate} from '../data/eventOptions'

export default ({
	type,
	tooltip = true,
	tooltipPlacement = 'left',
	tooltipProps,
	sx,
	...props
}) => {
	let findStatus
	findStatus = eventStatuses.find(eventStatus => eventStatus.slug === type)
	if (!findStatus) {
		findStatus = eventStatusesPrivate.find(eventStatus => eventStatus.slug === type)
	}
	if (!findStatus) {
		return
	}

	const icon = (
		<findStatus.Icon
			fontSize='small'
			sx={{
				marginRight: 2,
				verticalAlign: 'middle',
				color: findStatus.colours.hex,
				...sx
			}}
			{...props}
		/>
	)

	return tooltip ? (
		<Tooltip
			arrow
			disableInteractive
			placement={tooltipPlacement}
			title={findStatus.helpText || ''}
			{...tooltipProps}
		>
			{icon}
		</Tooltip>
	) : (
		icon
	)
}