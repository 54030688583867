import {useWatch} from 'react-hook-form'
import {Box} from '@mui/material'

import HeadlineArtistDetails from 'components/events/HeadlineArtistDetails'


export default ({
	name,
	control,
	...props
}) => {
	const headline = useWatch({
		control,
		name: 'headlineArtist'
	})
	
	return headline?.id && (
		<Box {...props}>
			<HeadlineArtistDetails headlineArtist={headline} />
		</Box>
	)
}