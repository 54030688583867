import axios from 'axios'

const mbBaseUrl = "https://musicbrainz.org/ws/2/"

export default (
	query,
	limit = 10
) => {
	if (!query) {return Promise.reject('Empty query')}
	const mbSearchUrl = new URL(mbBaseUrl+'artist')
	mbSearchUrl.searchParams.append('query', query)
	mbSearchUrl.searchParams.append('limit', limit)

	return axios.get(mbSearchUrl.href, {
		headers: {
			'Accept': 'application/json'
		}
	})
}
