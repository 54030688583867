import {
	Box,
	Paper,
	Typography
 } from '@mui/material'

 
const descriptions = {
	artistInformation: (<>
		<Typography>
			This section can be used to configure the lineup and/or running order for an event.
		</Typography>
		<Typography>
			The Headline Artist field can be used to search for and select the event's headliner - if the artist isn't listed, simply enter their name as text instead.
		</Typography>
		<Typography>
			Below that, the support artist(s) can be added, removed and re-ordered.
		</Typography>
		<Typography>
			All fields are optional, so the lineup can be configured even without start or end times.
		</Typography>
		<Typography fontWeight='bold'>
			Do not write the event name in the headline artist field.
		</Typography>
	</>),
	ticketing: (<>
		<Typography>
			This section is used to configure entry conditions and ticketing for an event.
		</Typography>
		<Typography>
			The ticket price is simply the face-value of the tickets (i.e not including booking fees) when purchased in advance, whereas the door price is the amount that customers will be charged on the door.
		</Typography>
	</>),
	description: (<>
		<Typography>
			Use this space to provide a description of the event. This can be the artist's biography or a summary of the event, as well as any pertinent details or particulars.
		</Typography>
	</>),
	eventImage: (<>
		<Typography>
			Image / artwork associated with the event.
		</Typography>
	</>),
	eventVideo: (<>
		<Typography>
			A YouTube video associated with the event.
		</Typography>
	</>),
	externalContact: (<>
		<Typography>
			This section can be used to store details regarding an external contact related to the event, e.g a promoter or agent.
		</Typography>
		<Typography>
			The address in the e-mail field may be used for automatically sending scheduled ticket reports. Multiple e-mail addresses can be entered, separated by commas.
		</Typography>
	</>)
}


export default ({
	children,
	descriptionProps = {},
	hideDescriptions = false,
	id,
	name,
	sx,
	...props
}) => (
	<Paper
		id={id}
		sx={{
			padding: 2,
			display: 'flex',
			flexDirection: 'column',
			gap: 2,
			...sx
		}}
		variant='outlined'
		{...props}
	>
		{name && (
			<Typography
				component='h2'
				variant='h5'
			>
				{name}
			</Typography>
		)}
		{(!hideDescriptions && descriptions[id]) && (
			<Box
				display='flex'
				flexDirection='column'
				gap={1}
				id={`${id}-description`}
				marginBottom={1}
				{...descriptionProps}
			>
				{descriptions[id]}
			</Box>
		)}
		{children}
	</Paper>
)
