import {getAuth, GoogleAuthProvider,} from 'firebase/auth'
import {initializeApp} from 'firebase/app'
import {getDatabase} from 'firebase/database'
import {getAnalytics} from 'firebase/analytics'

const firebaseConfig = process.env.NODE_ENV === 'development' ? {
	apiKey: "AIzaSyAMmvNTZpYG5q0IL-kVIi8iDRbnLC6E9_A",
	authDomain: "tlr-events-44619.firebaseapp.com",
	databaseURL: "https://tlr-events-44619-default-rtdb.europe-west1.firebasedatabase.app",
	projectId: "tlr-events-44619",
	storageBucket: "tlr-events-44619.appspot.com",
	messagingSenderId: "52946465911",
	appId: "1:52946465911:web:56ba43b20ff7de6778c272"
} : {
	apiKey: "AIzaSyAYJxoeXYtWNUucZSzFD8Y7g-rydCOaKR4",
	authDomain: "tlr-events.firebaseapp.com",
	databaseURL: "https://tlr-events-default-rtdb.europe-west1.firebasedatabase.app",
	projectId: "tlr-events",
	storageBucket: "tlr-events.appspot.com",
	messagingSenderId: "986603337656",
	appId: "1:986603337656:web:561f8aaac3e5c512ee414d"
}

export const firebaseApp = initializeApp(firebaseConfig)
const analytics = getAnalytics(firebaseApp)
export const provider = new GoogleAuthProvider()
export const db = getDatabase(firebaseApp)
export const auth = getAuth(firebaseApp)

export * from 'firebase/auth'
export * from 'firebase/database'