import {Icon} from '@iconify/react'
import {
	MenuItem,
	Link,
	ListItemIcon,
	ListItemText
} from '@mui/material'

/**
 * @param {string} name
 * @param {string} href
 * @param {string} icon iconify icon string
 * @param {object} iconProps props for the iconify icon
 */
export default ({
	name,
	url,
	icon,
	iconProps,
	...props
}) => (
	<MenuItem
		component={Link}
		href={url}
		target='_blank'
		rel='noreferrer'
		disabled={!url}
		{...props}
	>
		<ListItemIcon>
			<Icon icon={icon} {...iconProps} />
		</ListItemIcon>
		<ListItemText>
			{name}
		</ListItemText>
	</MenuItem>
)
