import {useEffect} from 'react'
import {useLocation} from 'wouter'
import {setPageTitle} from 'providers/slices/pageTitle'
import {useDispatch} from 'react-redux'
import {paths} from 'data/paths'

export default () => {
	const dispatch = useDispatch()
	const [location] = useLocation()

	useEffect(() => {
		const foundPath = paths.find(path => path.path === location)
		if (foundPath) {
			dispatch(setPageTitle(foundPath?.name))
		}
	}, [location])
	
	return null
}

