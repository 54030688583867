import {useSelector} from 'react-redux'

import {
	Box,
	Button,
	CardHeader,
	CardContent,
	MenuList,
	Skeleton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
} from '@mui/material'

import {eventStatuses} from 'data/eventOptions'

import {apiUrl} from 'functions/axios'
import {formatTime, now} from 'functions/formatDates'
import formatPrice from 'functions/formatPrice'
import CheckPermission from 'functions/CheckPermission'

import EventStatusIcon from 'components/EventStatusIcon'
import LinkButton from 'components/LinkButton'
import CollapsibleCard from 'components/CollapsibleCard'
import Image from 'components/Image'
import TableRow from 'components/dashboard/TableRow'
import DashboardCardMenuItem from 'components/dashboard/DashboardCardMenuItem'


import dayjs from 'dayjs'





const EventPriceValue = ({
	prices = {}
}) => {
	const ticket = (prices.ticket !== undefined && prices.ticket !== null) && formatPrice(prices.ticket)
	const door = (prices.door !== undefined && prices.door !== null) && formatPrice(prices.door)
	if (ticket && door) {
		return `${ticket} (${door} OTD)`
	} else if (ticket && !door) {
		return ticket
	} else if (!ticket && door) {
		return `${door} OTD`
	} else {
		return 'n/a'
	}
}


export default ({
	event,
	title,
	...props
}) => {
	const {loading} = useSelector(state => state.events)

	const mediaUrl = new URL(`events/image/${event.uid}`, apiUrl)

	const startObj = dayjs(event.start)
	let dateString = startObj.format(startObj.get('year') !== now.getFullYear() ? 'dddd, Do MMMM YYYY, HH:mm' : 'dddd, Do MMMM, HH:mm')
	if (event.end) {
		const endObj = dayjs(event.end)
		dateString += ` - ${formatTime(endObj)}`
	}
	const eventIsToday = startObj.isSame(dayjs(), 'day')

	const filteredSales = Object.entries(event.sales || {}).filter(([type, amount]) =>
		type !== 'guestlist'
	 &&	type !== 'door'
	 && amount > 0
	)
	const totalSales = filteredSales.reduce((total, currentValue) => total + currentValue[1], 0)

	const KeyCell = ({children}) => (
		<TableCell sx={{whiteSpace: 'pre'}}>
			{children}
		</TableCell>
	)

	const ValueCell = ({
		children,
		...props
	}) => (
		<TableCell {...props}>
			{loading ? (
				<Skeleton variant='text' />
			) : (
				children
			)}
		</TableCell>
	)

	return (
		<CollapsibleCard
			actions={[
				<CheckPermission
					key='viewEvent'
					permission='viewEvents'
				>
					{loading ? (
						<Skeleton variant='rounded' width='100%' height={30} />
					) : (
						<LinkButton
							fullWidth
							href={`/events/${event.uid}`}
							title='View Event'
						/>
					)}
				</CheckPermission>,
				<CheckPermission
					key='manageEvent'
					permission='manageEvents'
				>
					{loading ? (
						<Skeleton variant='rounded' width='100%' height={30} />
					) : (
						<LinkButton
							fullWidth
							href={`/events/${event.uid}/edit`}
							title='Edit Event'
						/>
					)}
				</CheckPermission>
			]}
			collapsedSubtitle={loading ? <Skeleton /> : event.name}
			id='nextEvent'
			title={loading ? <Skeleton /> : title || eventIsToday ? 'Today\'s Event' : 'Next Event'}
			variant='outlined'
			{...props}
		>
			{loading ? (
				<Skeleton height={250} sx={{mx: 1}} />
			) : (
				<Image
					src={mediaUrl.href}
					height={250}
					width='100%'
					crossOrigin='true'
					showLoading
				/>	
			)}
			

			<CardHeader
				title={loading ? <Skeleton /> : event.name}
				subheader={loading ? <Skeleton /> : dateString}
				subheaderTypographyProps={{
					fontSize: 'small'
				}}
				sx={{
					pb: 0
				}}
			/>

			<CardContent>
				<TableContainer>
					<Table size='small'>
						<TableBody>
							<TableRow>
								<KeyCell>Status</KeyCell>
								<ValueCell sx={{width: '60%'}}>
									<EventStatusIcon
										tooltip
										type={event.status}
										sx={{
											ml: 0,
											mr: .5,
											mb: .375,
											fontSize: '.8rem'
										}}
									/>
									{eventStatuses.find(eS => eS.slug === event.status)?.name}
								</ValueCell>
							</TableRow>
							<TableRow>
								<KeyCell>{event.room === 'L1 & L2' ? 'Rooms' : 'Room'}</KeyCell>
								<ValueCell>{event.room}</ValueCell>
							</TableRow>
							<TableRow>
								<KeyCell>Price</KeyCell>
								<ValueCell>
									<EventPriceValue prices={event.prices} />
								</ValueCell>
							</TableRow>
							<TableRow>
								<KeyCell>Sales</KeyCell>
								<ValueCell>{totalSales}</ValueCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
				{eventIsToday && (
					<Box sx={{mt: 1}}>
						<MenuList dense>
							<DashboardCardMenuItem
								name='Box Office sheet'
								icon='mi:document'
								iconProps={{color: '#1a73e8'}}
								url={event.google?.boxOfficeSheet?.url}
							/>
							<DashboardCardMenuItem
								name='Drive folder'
								icon='logos:google-drive'
								url={event.links?.drive}
							/>
						</MenuList>
					</Box>
				)}
			</CardContent>

		</CollapsibleCard>
	)
}