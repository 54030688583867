export const defaultValues = {
	ageRestriction: '',
	description: '',
	displayName: '',
	end: null,
	externalContact: {
		name: '',
		promoter: '',
		email: '',
	},
	headlineArtist: {},
	headlineArtistStartTime: null,
	hideFromUpcoming: false,
	hideFromWebsite: false,
	image: '',
	imageRemoved: false,
	name: '',
	notes: '',
	prices: {
		door: '',
		ticket: ''
	},
	room: '',
	start: null,
	status: '',
	supportArtists: [{
		name: '',
		start: null,
		end: null
	}],
	ticketUrl: '',
	type: '',
	youtubeId: ''
}

export const formItems = {
	ageRestriction: 'Age restriction',
	description: 'Description',
	displayName: 'Event Display Name',
	end: 'End date/time',
	externalContact: 'External contact',
	headlineArtist: 'Headline artist',
	headlineArtistStartTime: 'Headline artist start time',
	image: 'Image',
	imageRemoved: 'Image removed',
	name: 'Event name',
	prices: 'Prices',
	room: 'Room',
	start: 'Start date/time',
	status: 'Status',
	supportArtists: 'Supports',
	ticketUrl: 'Ticket URL',
	type: 'Type',
	youtubeId: 'YouTube ID'
}
