import {useFieldArray, useWatch, Controller} from 'react-hook-form'
import {
	Box,
	TextField,
	IconButton
} from '@mui/material'
import {TimePicker} from '@mui/x-date-pickers'
import {defaultValues} from 'components/events/defaultValues'

import {
	KeyboardArrowUp,
	KeyboardArrowDown,
	Add,
	Remove
} from '@mui/icons-material'

import dayjs from 'dayjs'
import minMax from 'dayjs/plugin/minMax'
import isBetween from 'dayjs/plugin/isBetween'

dayjs.extend(minMax)
dayjs.extend(isBetween)


export default ({
	control,
	name,
	setValue
}) => {
	const {
		fields,
		append,
		move,
		remove
	} = useFieldArray({
		name,
		control
	})

	const start = useWatch({
		control,
		name: 'start'
	})

	const end = useWatch({
		control,
		name: 'end'
	})

	const addNewSupport = () => append(defaultValues.supportArtists)
	const removeSupport = index => () => remove(index)
	const moveSupport = (index, newIndex) => () => move(index, newIndex)
	const handleChangeTime = (index, field) => value => {
		setValue(`${name}.${index}.${field}`, (
			start instanceof dayjs ? (
				dayjs(value)
					.set('year', start.get('year'))
					.set('month', start.get('month'))
					.set('date', start.get('date'))
					.set('seconds', 0)
					.set('ms', 0)
			) : (
				dayjs(value)
					.set('seconds', 0)
					.set('ms', 0)
			)
		))
	}

	return (<>
	
			{fields.map((field, index) => {
				return (
				<Box
					key={field.id}
					sx={{
						display: 'flex',
						alignItems: 'flex-end',
						gap: 1
					}
				}>
					<Box sx={{display: 'flex'}}>
						<IconButton
							disabled={index === 0}
							onClick={moveSupport(index, index-1)}
						>
							<KeyboardArrowUp />
						</IconButton>
						<IconButton
							disabled={index === fields.length-1}
							onClick={moveSupport(index, index+1)}
						>
							<KeyboardArrowDown />
						</IconButton>
					</Box>
					<Controller
						control={control}
						name={`${name}.${index}.name`}
						render={({field}) => (
							<TextField
								fullWidth
								name='name'
								size='small'
								label='Support Name'
								{...field}
							/>
						)}
					/>
					
					<TimePicker
						value={field.start ? dayjs(field.start): null}
						onChange={handleChangeTime(index, 'start')}
						name='start'
						label='Start'
						minutesStep={5}
						minTime={start}
						maxTime={end}
						disabled={!start}
						skipDisabled
						slotProps={{
							textField: {
								size: 'small'
							}
						}}
					/>

					<TimePicker
						value={field.end ? dayjs(field.end): null}
						onChange={handleChangeTime(index, 'end')}
						name='end'
						label='End'
						minutesStep={5}
						minTime={start}
						maxTime={end}
						disabled={!start}
						skipDisabled
						slotProps={{
							textField: {
								size: 'small'
							}
						}}
					/>
					{index === (fields.length-1) ? (
						<IconButton onClick={addNewSupport}>
							<Add />
						</IconButton>
					) : (
						<IconButton onClick={removeSupport(index)}>
							<Remove />
						</IconButton>
					)}
				</Box>
			)})}
	</>)
}