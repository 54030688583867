import {
	Box,
	Typography
 } from '@mui/material'

 
const SubSection = ({
	children,
	description,
	disabled,
	title,
	sx,
	...props
}) => {
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: 2,
				...sx
			}}
			{...props}
		>
			{title && (
				<Typography
					color={disabled ? 'text.disabled' : 'inherit'}
					component='h3'
					variant='h6'
				>
					{title}
				</Typography>
			)}

			{description && (
				<Typography
					color={disabled ? 'text.disabled' : 'inherit'}
					variant='body2'
				>
					{description}
				</Typography>
			)}

			{children}
		</Box>
	)
}

export default SubSection