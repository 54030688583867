import {useSelector} from 'react-redux'
import CollapsibleCard from 'components/CollapsibleCard'
import Link from 'components/Link'

import {
	Box,
	CardContent,
	Grid,
	Typography
} from '@mui/material'


export default ({
	...props
}) => {
	const {events} = useSelector(state => state.events)
	
	const actions = {}
	events.forEach(event => {
		if (['postponed', 'cancelled', 'notAnnounced'].includes(event.status)) {return}
		const eventActions = []
		if (!event.end) {eventActions.push('curfew')}
		if (!event.description) {eventActions.push('description')}
		if (!event.image) {eventActions.push('image')}
		// if (event.type === 'livemusic' && (!event.supportArtists || event.supportArtists.length === 0)) {eventActions.push('lineup')}
		if (!event.ticketUrl) {eventActions.push('ticket URL')}
		if ((event.type !== 'clubnight' && event.type !== 'other') && !event.youtubeId) {eventActions.push('youtube ID')}
		if (eventActions.length > 0) {
			actions[event.uid] = {
				...event,
				actions: eventActions
			}
		}
	})

	return Object.entries(actions).length > 0 && (
		<Box sx={{mb: 2}} {...props}>
			<CollapsibleCard
				id='actions'
				title='Actions'
			>
				<CardContent>
					<Typography variant='body1'>The following events are missing information:</Typography>
					<Grid container sx={{m: 1}}>
						{Object.entries(actions).map(([uid, event]) => (
							<Grid
								item
								key={uid}
								lg={4}
								md={6}
								sm={6}
								xs={12}
							>
								<Link to={`/events/${uid}/edit`}>
									{event.name}
								</Link> ({event.actions.join(', ')})
							</Grid>
						))}
					</Grid>
				</CardContent>
			</CollapsibleCard>
		</Box>
	)
}