import {useSelector} from 'react-redux'

export default ({
	permission = [],
	requireAuthOnly,
	ignoreAdmin,
	children
}) => {
	const {
		verified,
		verifiedUser
	} = useSelector(state => state.auth)

	if (typeof permission === 'string') {
		permission = [permission]
	}

	if (!verifiedUser?.permissions) {
		return null
	}

	if (
		(requireAuthOnly && verified)
	 || (!ignoreAdmin && verifiedUser.permissions.includes('admin'))
	 || (permission.some(requiredPermission => verifiedUser.permissions.includes(requiredPermission)))
	) {
		return children
	} else {
		return null
	}
}