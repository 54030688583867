import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
	clearEvents,
	setPastEvents,
	setError,
	setLoading
} from 'providers/slices/pastEvents'

import {api} from 'functions/axios'
import {getAuth} from 'providers/firebase'


export default () => {
	const dispatch = useDispatch()
	const {loading} = useSelector(state => state.pastEvents)
	const {verifiedUser} = useSelector(state => state.auth)
	const requiredPermissions = ['viewEvents', 'manageEvents']

	useEffect(() => {
		const requestPastEvents = async () => {
			if (verifiedUser?.permissions && requiredPermissions.some(x => verifiedUser.permissions.includes(x))) {
				if (!loading) {dispatch(setLoading(true))}
				const token = await getAuth().currentUser.getIdToken(true)

				api({
					url: 'events/past',
					headers: {
						token
					},
					params: {
						start: 0,
						end: Date.now()
					}
				}).then(response => {
					dispatch(setPastEvents(Object.values(response.data.events)))
				}).catch(error => {
					setError(error)
				})
			} else {
				dispatch(clearEvents())
			}
		}
		requestPastEvents()
	}, [verifiedUser?.permissions])
}