import {createSlice} from '@reduxjs/toolkit'

const initialState = {
	error: null,
	pastEvents: [],
	loading: true,
	totalPastEvents: 0
}

const eventsSlice = createSlice({
	name: 'pastEventsSlice',
	initialState,
	reducers: {
		clearEvents: (state, action) => {
			state.pastEvents = []
			state.error = null
			state.loading = false
		},
		setPastEvents: (state, action) => {
			state.pastEvents = action.payload
			state.totalPastEvents = action.payload?.length
			console.log(`[PastEvents]`, `loaded ${action.payload?.length} events`)
			state.loading = false
			state.error = null
		},
		setError: (state, action) => {
			console.error(action.payload)
			state.loading = false
			state.error = action.payload
		},
		setLoading: (state, action) => {
			state.loading = action.payload
		}
	}
})


export const {
	clearEvents,
	setPastEvents,
	setError,
	setLoading
} = eventsSlice.actions

export default eventsSlice.reducer