import {useEffect, useMemo} from 'react'
import {useSelector} from 'react-redux'
import {Route, Switch, useLocation, Redirect} from 'wouter'

import {ThemeProvider} from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import GlobalStyles from '@mui/material/GlobalStyles'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import 'dayjs/locale/en-gb'

import theme, {getScrollbarStyles} from 'providers/theme'
import Loading from 'components/Loading'
import RouteHandler from 'providers/RouteHandler'
import useAuthChangeHandler from 'functions/useAuthChangeHandler'
import useEvents from 'functions/useEvents'
import useUserData from 'functions/useUserData'
import useUserPreferences from 'functions/useUserPreferences'
import Layout from 'components/Layout'
import Login from 'pages/Login'
import NotFound from 'pages/404'

import PageTitleUpdater from 'functions/PageTitleUpdater'

import packageJson from 'packageJson'

import useSignOut from 'functions/useSignOut'

const AppLoading = ({children}) => {
	const loading = useSelector(state => state.loading.loading)
	const {
		authenticated,
		loading: authLoading,
		error,
		user,
		verified
	} = useSelector(state => state.auth)
	const {
		userData,
		loading: userDataLoading
	 } = useSelector(state => state.userData)
	const {loading: userPreferencesLoading} = useSelector(state => state.userPreferences)
	const {loading: eventsDataLoading} = useSelector(state => state.events)

	const [location, setLocation] = useLocation()

	const signOut = useSignOut()

	useEffect(() => {
		if (localStorage.getItem('version') !== packageJson.version) {
			localStorage.setItem('version', packageJson.version)
			signOut().then(() => {
				window.location.reload(true)
			})
		}
	}, [location])

	const isLoading = useMemo(() => loading, [loading])
	const isUserDataLoading = useMemo(() => userDataLoading, [userDataLoading])
	const isEventsDataLoading = useMemo(() => eventsDataLoading, [eventsDataLoading])
	const isUserPreferencesLoading = useMemo(() => userPreferencesLoading, [userPreferencesLoading])


	if (userData?.newUser) {
		return <Redirect to='/new-user' />
	}

	if (isLoading) {
		return <Loading message='Loading...' />
	}
	
	if (location !== '/login' && (!user || error)) {
		return <Redirect to='/login' />
	}

	if (authenticated && !verified) {
		return <Loading message={`Verifying ${user.name ?? 'user'}...`} />
	}

	if (isUserDataLoading || isUserPreferencesLoading) {
		return <Loading message='Loading your data...' />
	}

	if (isEventsDataLoading) {
		return <Loading message='Loading events...' />
	}

	if (authenticated && verified) {
		return (location === '/login') ? (
			<Redirect to='/' />
		) : (
			children
		)
	}

	if (authLoading) {
		return <Loading message='Loading*...' />
	}

	return children
}


const globalStyles = {
	...getScrollbarStyles()
}


const VerifiedSwitch = () => {
	const {verified} = useSelector(state => state.auth)
	return verified ? (
		<Layout>
			<RouteHandler />
		</Layout>
	) : (
		<Switch>
			<Route path='/login'>
				<Login />
			</Route>
			<Route>
				<NotFound />
			</Route>
		</Switch>
	)
}


const App = () => {
	useAuthChangeHandler()
	useUserData()
	useUserPreferences()
	useEvents()

	// process.env.NODE_ENV === 'development' && console.log(theme)

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<GlobalStyles styles={globalStyles} />
			<LocalizationProvider
				adapterLocale='en-gb'
				dateAdapter={AdapterDayjs}
			>
				<AppLoading>
					<PageTitleUpdater />
					<VerifiedSwitch />
				</AppLoading>
			</LocalizationProvider>
		</ThemeProvider>
	)
}

export default App