import {formatDateTime} from 'functions/formatDates'

const findMostRecentUpdate = updates => {
	let mostRecentObject = null

	for (const update of Object.values(updates)) {
		if (!mostRecentObject || update.datetime > mostRecentObject.datetime) {
			mostRecentObject = update
		}
	}

	return mostRecentObject
}


export default ({updates}) => {
	const mostRecentUpdate = findMostRecentUpdate(updates)
	return `${formatDateTime(mostRecentUpdate.datetime)} by ${mostRecentUpdate.user?.name}`
}