import dayjs from 'dayjs'
import compareArrayOfObjects from 'functions/compareArrayOfObjects'

export const makeCreateEventFormData = data => {
	const formData = new FormData()

	Object.entries(data).forEach(([key, value]) => {
		if (value === null) {return}
		if (value instanceof dayjs || value instanceof Date) {
			formData.set(key, value.valueOf())
		} else if (key === 'image') {
			if (value instanceof File) {
				formData.set(key, value)
			}
		} else if (key === 'supportArtists') {
			value.forEach(({start, end}, i) => {
				value[i].start = start instanceof dayjs ? start.valueOf() : null
				value[i].end = end instanceof dayjs ? end.valueOf() : null
				value[i].index = i
			})
			formData.set(key, JSON.stringify(value))
		} else {
			formData.set(key, typeof value === 'string' ? value : JSON.stringify(value))
		}
	})

	if (process.env.NODE_ENV !== 'production') {
		try {
			const obj = {}
			for (const key of formData.keys()) {
				obj[key] = formData.get(key)
			}
			console.log('formData', obj)
		} catch (e) {
			console.error(e)
		}
	}

	return formData
}

export const makeUpdateEventFormData = (data, event) => {
	const formData = new FormData()
	Object.entries(data).forEach(([key, value]) => {
		if (value === null) {return}
		
		if (value instanceof dayjs || value instanceof Date) {

			if (value && value.valueOf() !== event[key]) {
				formData.set(key, value.valueOf())
			}

		} else if (key === 'headlineArtist') {

			if (value?.name !== event[key]?.name) {
				formData.set(key, JSON.stringify(value))
			}

		} else if (key === 'image') {

			value instanceof File && formData.set(key, value)

		} else if (key === 'supportArtists') {

			if (!Array.isArray(value)) {
				return
			}

			if (Array.isArray(event.supportArtists) && compareArrayOfObjects(
				(event.supportArtists || []).map(({name, start, end}, i) => ({
					name,
					start: start instanceof dayjs ? start.valueOf() : null,
					end: end instanceof dayjs ? end.valueOf() : null,
					index: i //?
				})),
				value.map(({name, start, end}, i) => ({
					name,
					start: start instanceof dayjs ? start.valueOf() : null,
					end: end instanceof dayjs ? end.valueOf() : null,
					index: i //?
				}))
			)) {
				return
			}

			value.forEach(({start, end}, i) => {
				console.log(i, start, end)
				value[i].start = start instanceof dayjs ? start.valueOf() : null
				value[i].end = end instanceof dayjs ? end.valueOf() : null
				value[i].index = i
			})
			formData.set(key, JSON.stringify(value))

		} else if (key === 'prices') {

			if (
				value.door != event[key].door
			 || value.ticket != event[key].ticket
			) {
				formData.set(key, JSON.stringify(value))
			}

		} else if (key ==='externalContact') {
			
			if (
				(value.name && value.name != event[key]?.name)
			 || (value.email && value.email != event[key]?.email)
			 || (value.promoter && value.promoter != event[key]?.promoter)
			 ) {
				formData.set(key, JSON.stringify(value))
			}

		} else {
			
			const valueString = typeof value === 'string' ? value : JSON.stringify(value)
			if (valueString !== event[key] && valueString !== 'null') {
				formData.set(key, valueString)
			}

		}
	})

	if (process.env.NODE_ENV !== 'production') {
		try {
			const obj = {}
			for (const key of formData.keys()) {
				obj[key] = formData.get(key)
			}
			console.log('formData', obj)
		} catch (e) {
			console.error(e)
		}
	}
	
	return formData
}
