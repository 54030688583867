import {Button} from '@mui/material'
import {Link} from 'wouter'

export default ({
	href = '/',
	title = '',
	children,
	...props
}) => (
	<Button
		href={href}
		component={Link}
		{...props}
	>
		{title || children}
	</Button>
)