// import {useRouteError, useNavigate} from 'react-router-dom'
import {
	Box,
	Button,
	Typography
} from '@mui/material'


/**
 * @param error an error object
 * @param error.statusText error message
 * @param error.message error message
 * @param error.data misc message
 */
export default ({
	error,
	...props
}) => {
	const goBack = () => window.history.back()
	
	return (
		<Box sx={{
			padding: 2,
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			gap: 2
		}} {...props}>
			<Typography variant='h2'>
				Error
			</Typography>
			<Typography variant='subtitle1'>
				{error?.statusText ?? error?.message ?? 'Unknown error'}
			</Typography>
			{error?.data && (
				<Typography variant='subtitle2'>
					{error.data}
				</Typography>
			)}
			<Button
				onClick={goBack}
				
			>
				Go back
			</Button>
		</Box>
	)
}