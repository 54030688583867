import {ListItemText} from '@mui/material'

export default ({
	primary,
	...props
}) => (
	<ListItemText
		primary={primary}
		primaryTypographyProps={{
			sx: {
				textWrap: 'nowrap'
			},
			variant: 'subtitle2',
			visibility: {
				xs: 'hidden',
				md: 'visible'
			}
		}}
		{...props}
	/>
)