import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import CenterBox from 'components/CenterBox'
import {auth, provider} from 'providers/firebase'
import {
	clearUser,
	setError,
	setUser
} from 'providers/slices/auth'
import {setPageTitle} from 'providers/slices/pageTitle'
import {signInWithPopup} from 'firebase/auth'

import {
	Button,
	Alert,
	AlertTitle,
	Snackbar,
	Slide
} from '@mui/material'


export default () => {
	const dispatch = useDispatch()
	const error = useSelector(state => state.auth.error)

	useEffect(() => {
		dispatch(setPageTitle('Login'))
	}, [])
	
	const handleSignIn = async () => {
		try {
			const result = await signInWithPopup(auth, provider)
			dispatch(setUser({
				email: result.user.email,
				name: result.user.displayName,
				uid: result.user.uid
			}))
		} catch (error) {
			dispatch(clearUser())
			dispatch(setError(error))
			console.error(error)
		}
	}

	return (<>
		<CenterBox>
			<Button
				variant='contained'
				color='primary'
				onClick={handleSignIn}
			>
				Login
			</Button>
		</CenterBox>
		<Snackbar
			open={!!error}
			TransitionComponent={Slide}
			key='login_error'
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center'
			}}
		>
			<Alert severity='error'>
				<AlertTitle>{error?.code ?? 'Error'}</AlertTitle>
				{error?.message ?? error}
			</Alert>
		</Snackbar>
	</>)
}