import {useState, useEffect} from 'react'
import {useSelector} from 'react-redux'
import {db} from 'providers/firebase'
import {ref, update, onValue} from 'firebase/database'

import {
	Alert,
	Avatar,
	Box,
	Button,
	Chip,
	Collapse,
	IconButton,
	Paper,
	Stack,
	Tooltip,
	Typography,
	useTheme
} from '@mui/material'

import {
	DataGrid,
	GridActionsCellItem,
	GridEditInputCell,
	GridFooterContainer,
	GridPagination,
	GridRowEditStopReasons,
	GridRowModes
} from '@mui/x-data-grid'

import {
	Add,
	Check,
	Clear,
	Delete,
	Edit,
	Save
} from '@mui/icons-material'

import stringToColor from 'functions/stringToColor'


let errorId = 0


export default () => {
	const [staffingData, setStaffingData] = useState({
		personnel: [],
		roles: []
	})
	const [roleData, setRoleData] = useState([])

	const [loading, setLoading] = useState(true)
	const [errors, setErrors] = useState({})

	const [rowModesModel, setRowModesModel] = useState({})
	const [rows, setRows] = useState([])

	const verifiedUser = useSelector(state => state.auth.verifiedUser)


	const addError = ({
		code,
		message
	}) => {
		setErrors([
			...errors,
			{
				cleared: false,
				id: errorId++,
				message,
				title: code,
			}
		])
	}
	const clearError = id => setErrors(errors.filter(error => error.id !== id))


	useEffect(() => {
		const listener = onValue(
			ref(db, '/staffing'),
			snapshot => {
				setLoading(true)
				const {personnel, roles} = snapshot.val()
				setStaffingData({
					personnel: Object.entries(personnel).map(([key, object]) => ({
						slug: key,
						...object
					})),
					roles: Object.values(roles)
				})
				setLoading(false)
			}, error => {
				setLoading(false)
				addError(error)
			}
		)

		return () => listener()		
	}, [])
	

	useEffect(() => {
		setRows(Object.values(staffingData.personnel).map(({
			name,
			roles = [],
			slug
		}) => ({
			id: slug,
			roles: Object.values(roles),
			name
		})))
	}, [staffingData])


	const columns = [{
		description: 'Name',
		field: 'name',
		headerName: 'Name',
		hideable: false,
		minWidth: 180
	},{
		description: 'Roles',
		field: 'roles',
		headerName: 'Roles',
		hideSortIcons: true
	}]


	return (
		<Box sx={{
			display: 'flex',
			flexDirection: 'column',
			gap: 2,
			height: '90%'
		}}>
			{/* <Paper sx={{
				p: 1,
				fontStyle: 'italic',
				textAlign: 'center',
			}}>
				<Typography variant='subtitle2'>
					"Busy bees, thriving together"
				</Typography>
			</Paper> */}
			<DataGrid
				autoPageSize
				columns={columns}
				disableRowSelectionOnClick
				loading={loading}
				rows={rows}
				
				// editMode='row'
				// rowModesModel={rowModesModel}
				// onRowModesModelChange={handleRowModesModelChange}
				// onRowEditStop={handleRowEditStop}
				// processRowUpdate={processRowUpdate}
				// onProcessRowUpdateError={handleProcessRowUpdateError}

				columnVisibilityModel={{
					id: false
				}}

				// slots={{
				// 	footer: Footer
				// }}
			/>
		</Box>
	)
}