import {useEffect} from 'react'
import Link from 'components/Link'
import {useDispatch, useSelector} from 'react-redux'

import * as logos from 'data/logos.js'

import {setOpen} from 'providers/slices/sidebar'
import NavMenu from 'components/layout/NavMenu'

import packageJson from 'packageJson'

import {
	Box,
	Drawer,
	IconButton,
	SwipeableDrawer,
	Typography,
	useMediaQuery,
	useTheme
} from '@mui/material'

import {
	ChevronLeft,
	Menu as MenuIcon
} from '@mui/icons-material'


const drawerWidth = 240


export default () => {
	const theme = useTheme()
	const smallViewport = useMediaQuery(theme.breakpoints.down('md'))

	const dispatch = useDispatch()
	const drawerOpen = useSelector(state => state.sidebar.open)

	const openDrawer = () => dispatch(setOpen(true))
	const closeDrawer = () => dispatch(setOpen(false))
	const toggleDrawer = () => dispatch(setOpen(!drawerOpen))

	// useEffect(() => {
	// 	console.log(!smallViewport)
	// 	// dispatch(setOpen(!smallViewport))
	// 		if (smallViewport) {
	// 			console.log(1)
	// 			closeDrawer()
	// 		} else {
	// 			console.log(2)
	// 			openDrawer()
	// 		}
		
	// }, [smallViewport])
	console.log('rerender')

	const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent)

	const DrawerContents = () => (<>
		<Box id='logo' sx={{
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			mx: 1,
			...theme.mixins.toolbar
		}}>
			{drawerOpen && (
				<Link to='/'>
					<a>
						<Box sx={{
							width: '160px',
							height: '50px',
							backgroundImage: `url(${logos.dark})`,
							backgroundRepeat: 'no-repeat',
							backgroundSize: 'contain',
							backgroundPosition: 'center',
							mx: 1
						}} />
					</a>
				</Link>
			)}
			<IconButton
				onClick={toggleDrawer}
				size='large'
			>
				{drawerOpen ? <ChevronLeft /> : <MenuIcon />}
			</IconButton>
		</Box>

		<NavMenu
			drawerOpen={drawerOpen}
			closeDrawer={closeDrawer}
		/>

		{drawerOpen && (
			<Box sx={{
				m: 1,
				gap: 1,
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'flex-end'
			}}>
				<Box>

				</Box>
				<Box>
					<Typography
						variant='subtitle2'
						color='text.secondary'
					>
						v{packageJson.version}
					</Typography>
				</Box>
			</Box>
		)}
	</>)

	const transition = theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: drawerOpen ? theme.transitions.duration.enteringScreen : theme.transitions.duration.leavingScreen
	})


	return (
		<Box
			id='sidebar'
			component='nav'
			sx={{
				width: {
					md: (!smallViewport && !drawerOpen) ? theme.spacing(7) : drawerWidth
				},
				transition,
				flexShrink: {
					sm: 0
				}
			}}
		>
			{smallViewport ? (
				<SwipeableDrawer
					open={drawerOpen}
					onOpen={openDrawer}
					onClose={closeDrawer}
					SwipeAreaProps={{
						sx: {
							height: '90svh',
							top: '10svh'
						}
					}}
					PaperProps={{
						elevation: 2,
						sx: {
							boxShadow: theme.shadows[12]
						}
					}}
					disableBackdropTransition={!iOS}
					disableDiscovery={iOS}
					variant='temporary'
				>
					<DrawerContents />
				</SwipeableDrawer>
			) : (
				<Drawer
					variant={smallViewport ? 'temporary' : 'permanent'}
					open={drawerOpen}
					onClose={closeDrawer}
					ModalProps={{
						keepMounted: true
					}}
					PaperProps={{
						elevation: 0,
						sx: {
							boxSizing: 'border-box',
							overflowX: 'hidden',
							width: (!smallViewport && !drawerOpen) ? theme.spacing(7) : drawerWidth,
							borderRight: 'unset',
							transition
						}
					}}
					sx={{
						boxSizing: 'border-box',
						whiteSpace: 'nowrap',
						overflowX: 'hidden',
						transition,
						...(!smallViewport && !drawerOpen) && {
							width: theme.spacing(7)
						}
					}}
				>
					<DrawerContents />
				</Drawer>
			)}
		</Box>
	)
}