import {useState, useEffect} from 'react'
import Link from 'components/Link'
import {
	CircularProgress,
	Button,
	Typography,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle
} from '@mui/material'


const titles = {
	create: {
		loading: 'Creating event...',
		success: 'Event created',
		failure: 'Error creating event'
	},
	update: {
		loading: 'Updating event...',
		success: 'Event updated',
		failure: 'Error updating event'
	}
}


export default ({
	open,
	setOpen,
	type,
	loading,
	response,
	...props
}) => {
	const [title, setTitle] = useState('')

	const hasError = response?.name === 'AxiosError'
	
	const handleClose = () => {
		if (hasError || !loading || process.env.NODE_ENV !== 'production') {
			setOpen(false)
		}
	}

	useEffect(() => {
		if (loading) {
			setTitle(titles[type].loading)
		} else if (hasError) {
			setTitle(titles[type].failure)
		} else {
			setTitle(titles[type].success)
		}
	}, [hasError, titles, loading, type])

	return (<>
		<Dialog
			open={open}
			onClose={handleClose}
			disableEscapeKeyDown
			sx={{minWidth: '600px'}}
		>
			<DialogTitle>
				{title}
			</DialogTitle>

			<DialogContent>
				{loading ?
					<CircularProgress sx={{margin: '0 auto', display: 'block'}} />
				:
					hasError ? (<>
						{response.message &&
							<Typography>
								{response.message}
							</Typography>
						}
						{response.response &&
							Array.isArray(response.response.data?.errors) ? (
								response.response.data.errors.map((error, i) => (
									<Typography key={i}>
										{i+1}. {error.msg}
									</Typography>
								))
							) : (
								JSON.stringify(response.response?.data)
							)
						}
					</>) : (<>
						<Typography>
							Success
						</Typography>
					</>)
				}
			</DialogContent>

			{!loading && 
				<DialogActions>
					{hasError ? (<>
						<Button size='small' onClick={handleClose}>Close</Button>
					</>) : (<>
						<Link to={`/events/${response?.uid || ''}`} reloadDocument style={{textDecoration: 'none'}}>
							<Button size='small'>View Event</Button>
						</Link>
						{type !== 'update' && <Link to='/events/create' reloadDocument style={{textDecoration: 'none'}}>
							<Button size='small'>Create Another</Button>
						</Link>}
					</>)}
				</DialogActions>
			}
		</Dialog>
	</>)
}