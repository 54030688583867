import {useEffect} from 'react'
import {useWatch, useController} from 'react-hook-form'
import {
	Box,
	TextField,
	InputAdornment
} from '@mui/material'
import {defaultValues as initialDefaultValues} from 'components/events/defaultValues'

const validPricePattern = /^([0-9]+)?(\.)?([0-9]{1,2})?$/

const PriceBox = ({children, sx, ...props}) => (
	<Box sx={{
		display: 'flex',
		grap: 1,
		flexDirection: 'column',
		flexGrow: 1,
		maxWidth: '50%',
		...sx
	}} {...props}>
		{children}
	</Box>
)

export const TicketPrice = ({name, error, control}) => {
	const {field} = useController({
		name,
		control,
		rules: {
			min: {
				value: 0,
				message: 'Amount can\'t be negative'
			},
			pattern: {
				value: validPricePattern,
				message: 'Invalid price'
			}
		}
	})

	return (
		<PriceBox>
			<TextField
				{...field}
				fullWidth
				size='small'
				variant='outlined'
				label='Ticket Price'
				type='number'
				required
				inputProps={{
					min: 0,
					step: .5
				}}
				InputProps={{
					startAdornment: <InputAdornment position='start'>£</InputAdornment>
				}}
				error={!!error}
				helperText={error?.message}
			/>
		</PriceBox>
	)
}


export const DoorPrice = ({name, error, control}) => {
	const {
		field,
		formState: {
			touchedFields,
			defaultValues
		}
	} = useController({
		name,
		control,
		rules: {
			min: {
				value: 0,
				message: 'Amount can\'t be negative'
			},
			pattern: {
				value: validPricePattern,
				message: 'Invalid price'
			}
		}
	})

	const priceTicket = useWatch({
		control,
		name: 'prices.ticket'
	})

	useEffect(() => {
		if (!touchedFields.prices?.door && priceTicket && defaultValues.prices?.door === initialDefaultValues.prices?.door) {
			field.onChange(Number(priceTicket) + 3)
		}
	}, [priceTicket])
	
	return (
		<PriceBox>
			<TextField
				{...field}
				fullWidth
				size='small'
				variant='outlined'
				label='Door Price'
				type='number'
				required
				inputProps={{
					min: 0,
					step: .5
				}}
				InputProps={{
					startAdornment: <InputAdornment position='start'>£</InputAdornment>,
					...(!touchedFields.prices?.door && defaultValues.prices?.door === initialDefaultValues.prices?.door) && {
						sx: {
							color: 'text.secondary'
						}
					}
				}}
				error={!!error}
				helperText={error?.message}
			/>
		</PriceBox>
	)
}

