import {createSlice} from '@reduxjs/toolkit'


const initialState = {
	error: null,
	loading: true,
	userData: null
}


const authSlice = createSlice({
	name: 'userData',
	initialState,
	reducers: {
		setUserData: (state, action) => {
			state.userData = action.payload
			state.loading = false
			state.error = null
		},
		clearUserData: (state, action) => {
			state.userData = null
			state.loading = false
			state.error = null
		},
		setLoading: (state, action) => {
			state.loading = action.payload
		},
		setError: (state, action) => {
			state.error = action.payload
			state.loading = false
		}
	}
})

export const {
	setUserData,
	clearUserData,
	setLoading,
	setError
} = authSlice.actions

export default authSlice.reducer