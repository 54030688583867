import {TableCell} from '@mui/material'

export default ({
	children,
	...props
}) => (
	<TableCell
		component='th'
		scope='row'
		{...props}
	>
		{children}
	</TableCell>
)